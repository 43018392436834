import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
//import { useState } from 'react';

import tablaMeses from "../Tablas/Tablameses";
const Meses = ({ mes, setMes }) => {
  const navigate = useNavigate();
  const handleMes = (p) => {
    setMes(p);
    navigate("/Mes");
  };

  return (
    <section Meses>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h3> Meses del Año</h3>
      </div>
    
      <Container>
      <p>Cada mes del calendario hebreo tiene su propia energía y significado
          único. Desde la renovación y el crecimiento en Shevat hasta la alegría
          y la celebración en Adar, cada mes nos invita a conectarnos con
          aspectos específicos de nuestra vida y espiritualidad. A lo largo del
          calendario, encontramos meses de liberación y renovación, como Nisan y
          Elul, así como meses de reflexión y arrepentimiento, como Tamuz y Av.
          También hay meses dedicados al estudio y la sabiduría, como Sivan y
          Tevet. Cada mes nos ofrece oportunidades para profundizar nuestra
          conexión con lo divino, encontrar significado en nuestras vidas y
          celebrar nuestra herencia y tradiciones judías. A través del ciclo
          anual, experimentamos una variedad de emociones, aprendizajes y
          rituales que enriquecen nuestra experiencia espiritual y nos permiten
          crecer como individuos y comunidad.
        </p>
        <Row xs={1} md={2} lg={3} className="g-10 text-center">
          {tablaMeses.map((p, idx) => (
            <Col p={p} key={idx}>
              <Card style={{ width: "22rem", height: "35rem" }} className="m-3">
                <Card.Body>
                  <Card.Title>{p.nombre} </Card.Title>
                  <Card.Title> {p.hebreo} </Card.Title>
                  <Card.Img variant="top" src={p.url} />
                  <Card.Text>{p.descripcion}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      variant="text"
                      size="small"
                      fullWidth={true}
                      color="primary"
                      onClick={() => handleMes(p.nombre)}
                    >
                      {" "}
                      Leer más{" "}
                    </Button>
                  </Card.Footer>
              
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Meses;

import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from 'react-bootstrap/Card';
function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const itemData = [
  {
    img: './fiestas/fiesta1.JPG',
    rows: 2,
    cols: 2,
  },
  {
    img: './fiestas/fiesta2.JPG',
    title: 'Chef preparando ensalada',
  },
  {
    img: './fiestas/fiesta3.JPG',
    title: 'Chef preparando pasta',
  },
  {
    img: './fiestas/fiesta4.JPG',
    title: 'Chef preparando postres',
    cols: 2,
  },
  {
    img: './fiestas/fiesta5.JPG',
    title: 'Barman ssirviendo un Coctail',
    cols: 2,
  },
  {
    img: './fiestas/fiesta6.JPG',
    rows: 2,
    cols: 2,
  },
  {
    img: './fiestas/fiesta7.JPG',
    title: 'Chef preparando pasta',
  },
  {
    img: './fiestas/fiesta8.JPG',
    title: 'Clientes comiendo pasta',
  },
];

const Nosotros = () => {
  return (
    <section nosotros className="mb-5">
      <div className="bg-dark text-bg-dark pb-2 mb-5 ps-5  mb-1 text-center">
        <h3> Sobre Nosotros </h3>
      </div>
      <Box sx={{ flexGrow: 1, }}>
        <Grid container spacing={{ xs: 1, md: 4, lg: 5 }}
          columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }} display="flex" justifyContent="center">
          <Grid item className="p-5" >
            <ImageList
              xs={{ width: 400, height: 400 }}
              sx={{ width: 500, height: 500 }}
              md={{ width: 600, height: 600 }}
              lg={{ width: 700, height: 700 }}
              xl={{ width: 900, height: 700 }}
              variant="quilted"
              cols={4}
              rowHeight={121}
            >
              {itemData.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                  <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
          <Grid item className="p-5" style={{ width: '40rem' }}>
            <Card className="p-3" style={{ width: '40rem' }}>
              <Card.Body   >
                <Card.Text>
                  En mi camino personal he embarcado en un proceso de estudio de la Torá y las mitzvot. Estas enseñanzas han iluminado mi comprensión de que hemos venido a este mundo con un propósito: trabajar en el desarrollo de nuestras midot, nuestras virtudes éticas, y utilizar nuestros dones y habilidades como herramientas para construir y contribuir de manera significativa.
                </Card.Text>
                <Card.Text>
                 He descubierto que el trabajo interior y la práctica de las mitzvot nos brindan una oportunidad valiosa para crecer, transformarnos y conectarnos con lo divino en cada aspecto de nuestras vidas. Cada día, cada encuentro y cada desafío se convierten en un lienzo en el que podemos ejercitar nuestras midot y construir conexión más profunda con el Creador.
                </Card.Text>
                <Card.Text>
                  Considero que mi formación como ingeniero informático y mi estudio de la Torá son complementarios, ya que ambos me invitan a utilizar mi mente analítica y mis habilidades técnicas para crear soluciones innovadoras y al mismo tiempo nutrir mi alma haciendo mi trabajo de midot.
                </Card.Text>
                <Card.Text>
                Cada momento en el calendario hebreo está imbuido de una energía especial y única, brindándonos una valiosa oportunidad de profundizar en el trabajo de nuestras midot. 
                Reconocer y aprovechar conscientemente estas energías nos permite avanzar en nuestro camino espiritual. Al sintonizarnos con estas energías, nos abrimos a la transformación personal y al crecimiento espiritual, aprovechando el potencial de cada momento para nutrir nuestro interior y forjar un camino más significativo para nuestra vida.
                </Card.Text>
                <Card.Text>
                Este sitio combina mi vocación profesional con mi compromiso personal de crecimiento espiritual y servicio a los demás. Mi objetivo es contribuir desinteresadamente proporcionando herramientas que te ayuden en tu trabajo y camino espiritual. Aquí encontrarás recursos prácticos y reflexiones significativas para enriquecer tu experiencia y facilitar tu crecimiento personal."
                </Card.Text>
              </Card.Body>
            </Card>
          </Grid>
        </Grid>

      </Box>
    </section>
  );
}
export default Nosotros
let tablaOraciones = [
    {
      id: 1,
      nombre: "Shajarit",
      descripcion: "Oración de la mañana",
      horario: "Desde la salida del sol hasta antes del mediodía",
      proposito: "Comenzar el día con agradecimiento y dedicación a Dios",
    },
    {
      id: 2,
      nombre: "Minjá",
      descripcion: "Oración de la tarde",
      horario: "Desde después del mediodía hasta antes del anochecer",
      proposito: "Reflexionar sobre las acciones del día y pedir perdón",
    },
    {
      id: 3,
      nombre: "Arvit",
      descripcion: "Oración de la noche",
      horario: "Desde el anochecer hasta la medianoche",
      proposito: "Finalizar el día y confiar en la protección divina durante la noche",
    },
  ];

  export default tablaOraciones;
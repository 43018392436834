import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

import tablaMidot from "../Tablas/Tablamidot";
const Midot = () => {
  return (
    <section Midot>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h3> Midot</h3>
      </div>
      <Container>
        <Row xs={1} md={2} lg={3} className="g-10 text-center">
          {tablaMidot.map((p, idx) => (
            <Col p={p} key={idx}>
              <Card style={{ width: "22rem", height: "32rem" }} className="m-3">
                <Card.Body>
                  <Card.Title>{p.nombre}</Card.Title>
                  <Card.Text>{p.significado}</Card.Text>
                  <Card.Text>{p.destacado}</Card.Text>              
                  <Card.Text>{p.comoTrabajarla}</Card.Text>
                  <Card.Text>{p.desequilibrio}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Midot;

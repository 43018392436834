import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import lazo from '../../images/footer/lazo.png';
const Navigation = () => {
  return (
    <header className="bg-dark ps-5">
      <Navbar variant="dark" expand="lg">
        <Navbar.Brand href="/">
          <img
            src={lazo}
            width="120"
            height="120"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
      
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link as={NavLink} to="/">Home</Nav.Link>
          <Nav.Link as={NavLink} to="/tiempo">El tiempo</Nav.Link>
          <Nav.Link as={NavLink} to="/jeshbon">jeshbon</Nav.Link>{
/*
            <NavDropdown title='El tiempo'>
              <NavDropdown.Item as={NavLink} to="/ciclodevida">El ciclo de la vida</NavDropdown.Item >
              <NavDropdown.Item as={NavLink} to="/festividades">El año y sus Festividades</NavDropdown.Item >
              <NavDropdown.Item as={NavLink} to="/meses">Los Meses</NavDropdown.Item >
              <NavDropdown.Item as={NavLink} to="/parashot">Las semanas y sus Parashot</NavDropdown.Item >
              <NavDropdown.Item as={NavLink} to="/dias">Los Dias de la semana</NavDropdown.Item >
              <NavDropdown.Item as={NavLink} to="/horas">Las Horas del día</NavDropdown.Item >
            </NavDropdown>
*/
          }

            <NavDropdown title='Midot y Mitzvot'>
              
              <NavDropdown.Item as={NavLink} to="/mitzvot">Mitzvot</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/midot">Midot</NavDropdown.Item >
              <NavDropdown.Item as={NavLink} to="/sefirot">Las Sefirot</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/omer">Cuenta del Omer</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Liturgia'>
              <NavDropdown.Item as={NavLink} to="/oraciones">Oraciones diarias</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/rezos">Liturgia</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to="/contacto">Contacto</Nav.Link>
            <Nav.Link as={NavLink} to="/nosotros">Nosotros</Nav.Link>
            <Nav.Link as={NavLink} to="/prueba">Prueba</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Navigation;

let tablaCicloDeVida = [
    {
      id: 1,
      nombre: "Nacimiento",
      descripcion: "Ceremonias y bendiciones para celebrar el nacimiento de un niño",
      significado: "El nacimiento es considerado un milagro en el judaísmo y se celebra como un regalo de Dios. Representa la continuidad de la vida y la esperanza en el futuro. Además, el nacimiento de un niño es un recordatorio de la responsabilidad de criar y educar al niño según los valores y enseñanzas judías, transmitiendo así la herencia espiritual a las generaciones futuras.",
      imagen: "/fondos/nacimiento.PNG",
    },
    {
      id: 2,
      nombre: "Circuncisión",
      descripcion: "Rito de circuncisión para los niños varones en el octavo día de vida",
      significado: "La circuncisión es un rito de iniciación importante en el judaísmo. Representa la alianza entre Dios y el pueblo judío, establecida con Abraham. Además de ser un mandamiento divino, la circuncisión simboliza la pureza, la identidad judía y el compromiso de vivir una vida de integridad, moralidad y obediencia a los mandamientos de la Torá.",
      imagen: "/fondos/brit.PNG",
    },
    {
      id: 3,
      nombre: "Bar Mitzvá",
      descripcion: "Celebración del paso a la adultez religiosa para los niños a los 13 años",
      significado: "El Bar Mitzvá marca la transición de la niñez a la adultez religiosa en el judaísmo. A los 13 años, los niños se convierten en responsables de cumplir los mandamientos y participar activamente en la comunidad judía. Esta celebración implica el estudio de la Torá, la lectura de un pasaje de la Torá durante la sinagoga y el asumir la responsabilidad de tomar decisiones éticas y espirituales en la vida cotidiana.",
      imagen: "/fondos/bar.PNG",
    },
    {
      id: 4,
      nombre: "Bat Mitzvá",
      descripcion: "Celebración del paso a la adultez religiosa para las niñas a los 12 años",
      significado: "El Bat Mitzvá es una celebración significativa que marca la llegada a la adultez religiosa para las niñas en el judaísmo. A los 12 años, las niñas adquieren la responsabilidad religiosa y moral de cumplir los mandamientos y participar activamente en la comunidad. Esta celebración representa el desarrollo de la identidad judía de la joven, su conexión con la tradición y la historia del pueblo judío, y su capacidad para tomar decisiones éticas y espirituales.",
      imagen: "/fondos/bat.PNG",
    },
    {
      id: 5,
      nombre: "Matrimonio",
      descripcion: "Ceremonia y rituales para la unión matrimonial según las tradiciones judías",
      significado: "El matrimonio en el judaísmo es considerado un acto sagrado y una unión de almas. Representa la creación de una nueva familia basada en el amor, la compañía y el respeto mutuo, así como en la colaboración para cumplir con los mandamientos y vivir una vida judía comprometida. El matrimonio también simboliza la continuidad de la tradición judía y la responsabilidad de construir un hogar en el que se transmitan los valores y las enseñanzas judías a las generaciones futuras.",
      imagen: "/fondos/jupa.PNG",
    },
    {
      id: 6,
      nombre: "Duelo",
      descripcion: "Prácticas y rituales de luto y consuelo después de la pérdida de un ser querido",
      significado: "El duelo en el judaísmo es un proceso sagrado que permite a las personas que han perdido a un ser querido honrar su memoria y encontrar consuelo. Se realizan rituales y prácticas como el velatorio, el entierro y el período de duelo, durante el cual se recita la oración del duelo, se estudia la Torá y se busca el apoyo de la comunidad. El duelo también es un momento de reflexión sobre la fragilidad de la vida y la importancia de valorar cada momento y las relaciones significativas que tenemos.",
      imagen: "/fondos/duelo.PNG",
    },
  ];
  
  export default tablaCicloDeVida;
let tablaSefirot = [
  {
    id: 1,
    nombre: "Keter",
    hebreo: "כֶּתֶר",
    descripcion:
      "Keter es la Corona, representa la voluntad suprema que inicia la Creación.",
  },
  {
    id: 2,
    nombre: "Jojma",
    hebreo: "חָכְמָה",
    descripcion: "Jojma, la Sabiduría, es el primer impulso de la creación.",
  },
  {
    id: 3,
    nombre: "Bina",
    hebreo: "בִּינָה",
    descripcion:
      "Bina, la Comprensión, es el aspecto de la mente que examina y comprende las ideas.",
  },
  {
    id: 4,
    nombre: "Jesed",
    hebreo: "חֶסֶד",
    descripcion:
      "Jesed, la Misericordia, es la fuerza positiva de la bondad y el amor.",
  },
  {
    id: 5,
    nombre: "Guevura",
    hebreo: "גְּבוּרָה",
    descripcion:
      "Guevura, la Severidad, es la fuerza de la justicia y la limitación.",
  },
  {
    id: 6,
    nombre: "Tiferet",
    hebreo: "תִּפְאֶרֶת",
    descripcion:
      "Tiferet, la Belleza, es el equilibrio entre Chesed y Gevurah.",
  },
  {
    id: 7,
    nombre: "Netzaj",
    hebreo: "נֵצַח",
    descripcion: "Netzaj, la Eternidad, es la perseverancia y la resistencia.",
  },
  {
    id: 8,
    nombre: "Hod",
    hebreo: "הוֹד",
    descripcion:
      "Hod, la Gloria, representa el poder de la concreción y la materialización.",
  },
  {
    id: 9,
    nombre: "Yesod",
    hebreo: "יְסוֹד",
    descripcion:
      "Yesod, el Fundamento, es la capacidad de conectar y comunicar.",
  },
  {
    id: 10,
    nombre: "Maljut",
    hebreo: "מַלְכוּת",
    descripcion:
      "Maljut, el Reino, representa la manifestación física de la creación.",
  },
];
export default tablaSefirot;

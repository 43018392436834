
import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import { List } from "bootstrap-4-react";

//import { useState } from 'react';

import tablaHoras from "../Tablas/Tablahoras";
const Horas = () => {
  return (
    <section Horas>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h3> Horas del día</h3>
      </div>
      <Container>
      <p>Las horas, por último, son unidades aún más pequeñas del tiempo que nos recuerdan la importancia de cada momento. A lo largo del día, tenemos la oportunidad de ser conscientes de nuestras acciones, cultivar nuestras virtudes y aprovechar al máximo cada instante. Cada hora nos ofrece una nueva oportunidad de enfoque, productividad y conexión con lo divino.</p>
 
        <Row xs={1} md={2} lg={3} className="g-4 text-center">
          {tablaHoras.map((p, idx) => (
            <Col p={p} key={idx}>


              <Card style={{ width: "20rem", height: "30rem" }} className="m-3 p-3">
               
               <Card.Body>
               <Card.Title>{p.nombre} </Card.Title>
                  <Card.Title> {p.hebreo} </Card.Title>
                  <Card.Img variant="top" mb="2" src={p.url} height={"80"} />
                  <Card.Text>{p.descripcion}</Card.Text>
               </Card.Body>
               <Card.Footer>
               <Card.Text><h6> Midot para trabajar</h6> </Card.Text>
               </Card.Footer>
               <Card.Footer >
                 <List unstyled>
                   {p.mida.map((q, idx1) => (
                     <List.Item> {q}</List.Item>
                   ))}
                 </List>
               </Card.Footer>
             </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Horas;


const tablaHoras = [
    {
      id: 1,
      nombre: "Mañana temprano",
      descripcion:
        "Las primeras horas del día, llenas de frescura y renovación. Es un momento para despertar con gratitud y establecer intenciones positivas para el día.",
      mida: ["Gratitud", "Renovación", "Intenciones positivas"], hebreo: "בֹּקֶר מוֹקְדָם",
    },
    {
      id: 2,
      nombre: "Mañana",
      descripcion:
        "El transcurso de la mañana, cuando la energía y la productividad están en su punto máximo. Es un buen momento para realizar tareas importantes y enfocarse en el trabajo.",
      mida: ["Energía", "Productividad", "Enfoque"], hebreo: "בֹּקֶר" ,
    },
    {
      id: 3,
      nombre: "Mediodía",
      descripcion:
        "La mitad del día, un momento para tomar un descanso y recargar energías. Es recomendable hacer una pausa para comer y reponerse físicamente.",
      mida: ["Descanso", "Recarga de energías", "Nutrición"], hebreo: "צָהֳרַיִם" ,
    },
    {
      id: 4,
      nombre: "Tarde",
      descripcion:
        "El transcurso de la tarde, un momento para retomar tareas pendientes y aprovechar la energía que queda del día. Es un buen momento para ser creativo y colaborar con otros.",
      mida: ["Productividad", "Creatividad", "Colaboración"], hebreo: "אַחֲרֵי הַצָּהֳרַיִם" ,
    },
    {
      id: 5,
      nombre: "Atardecer",
      descripcion:
        "El final del día, un momento para reflexionar sobre lo realizado y cerrar el día con gratitud. Es recomendable desconectar del trabajo y dedicarse a actividades de relajación.",
      mida: ["Reflexión", "Gratitud", "Relajación"], hebreo: "בֵּין הַשְּׁמַשּׁוֹת" ,
    },
    {
      id: 6,
      nombre: "Noche",
      descripcion:
        "El tiempo de descanso y renovación durante la noche. Es importante establecer una rutina para un sueño reparador y prepararse para el próximo día.",
      mida: ["Descanso", "Renovación", "Rutina de sueño"], hebreo: "לַיְלָה",
    },
  ];
  
  export default tablaHoras;
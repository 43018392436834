let tablaFestividades = [
  // Rosh Hashaná
  {
    id: 1,
    festividad: "Rosh Hashaná",
    resumen: "El año nuevo judío",
    descripcion:
      "Rosh Hashaná marca el comienzo del año nuevo judío. Es un tiempo de reflexión, arrepentimiento y renovación espiritual. Se toca el shofar y se come la manzana con miel como símbolos de dulzura y buenos deseos para el año venidero.",
    midot: ["Arrepentimiento", "Renovación", "Autoevaluación"],
    energia: "Energía de introspección y renovación",
    url: "fiestas/fiesta1.JPG",
    frase: "La consagración de la vida",
    parrafo1:
      "Durante Rosh Hashaná, los judíos realizan un examen de vida y propósito en este mundo. Se cree que Dios evalúa sus acciones y actitudes, y se les insta a reflexionar profundamente sobre sus logros, desafíos y arrepentimientos del año anterior. El sonido del shofar y las oraciones especiales llaman a la introspección y al cambio positivo. Rosh Hashaná es un momento de reflexión, renovación espiritual y compromiso de mejorar en el próximo año, buscando una conexión más fuerte con Dios y con los demás.",
    parrafo2:
      'Rosh Hashaná marca el comienzo de los "Días de Temor" (Yamim Noraim), un período de diez días que culmina con Yom Kipur, el Día de la Expiación. Durante estos días, se alienta a las personas a reflexionar profundamente sobre sus acciones y su relación con Dios y su comunidad. Se considera un momento para hacer una revisión de vida, un autoexamen de las metas alcanzadas y los desafíos enfrentados durante el año anterior.',
    parrafo3:
      'Durante las oraciones de Rosh Hashaná, se recita una serie de oraciones especiales, conocidas como las "Malkuyot, Zijronot y Shofarot". Estas oraciones hacen referencia a tres aspectos centrales de la festividad : la soberanía de Dios sobre el mundo (Malkuyot), el recuerdo divino de las acciones humanas (Zijronot) y el sonido del shofar que llama a la reflexión y al arrepentimiento (Shofarot).',
    parrafo4:
      "El shofar, el cuerno de carnero utilizado durante Rosh Hashaná, tiene un significado profundo. Su sonido estruendoso y penetrante es considerado un despertador para el alma, un recordatorio de la necesidad de examinar nuestras acciones y corregir aquello en lo que hemos fallado. El shofar simboliza un llamado a la introspección, la humildad y el compromiso de hacer cambios positivos en nuestras vidas.",
    parrafo5:
      "Además de las oraciones y el sonido del shofar, Rosh Hashaná es una ocasión para compartir comidas especiales en familia y con la comunidad. Durante estas comidas, se suele reflexionar sobre los desafíos y logros del año pasado, y se establecen metas y propósitos para el año nuevo.",
  },
  // Iom Kipur
  {
    id: 2,
    festividad: "Iom Kipur",
    resumen: "Día del perdón",
    descripcion:
      "Yom Kipur es el día del perdón y el ayuno más sagrado del calendario judío. Es un momento de arrepentimiento, búsqueda de la expiación y purificación. Se pasa el día en oración y reflexión, pidiendo perdón a Dios y a nuestros semejantes.",
    midot: ["Arrepentimiento", "Perdón", "Autoevaluación"],
    energia: "Energía de purificación y perdón",
    url: "fiestas/fiesta2.JPG",
    frase: "",
    parrafo1:
      "Yom Kipur, el Día de la Expiación, es el día más sagrado del calendario judío. Durante este día, los judíos se abstienen de comer, beber y realizar actividades mundanas. Es un tiempo dedicado al arrepentimiento, la expiación y la búsqueda del perdón divino. Se asiste a servicios religiosos en los que se recitan oraciones especiales y se realizan confesiones comunitarias de los pecados. Yom Kipur es un día de introspección profunda, ayuno",
    parrafo2:
      'Yom Kipur, también conocido como el Día de la Expiación, es el día más sagrado y solemne del calendario judío. Se celebra diez días después de Rosh Hashaná, marcando el cierre del ciclo de los "Días de Temor" (Yamim Noraim). Durante este día, los judíos observantes se abstienen de comer, beber, tener relaciones sexuales y realizar actividades mundanas. Yom Kipur se dedica principalmente al arrepentimiento, la expiación y la búsqueda del perdón divino.',
    parrafo3:
      "Este día es considerado un tiempo para enfrentar honestamente los errores cometidos durante el año anterior y buscar el perdón y la reconciliación. Los judíos se sumergen en una introspección profunda, evaluando sus acciones y actitudes, y reconociendo cualquier daño causado a otros o a sí mismos. Se realiza un examen de conciencia minucioso para identificar áreas de mejora y arrepentirse sinceramente de los pecados cometidos.",
    parrafo4:
      "Durante Yom Kipur, los fieles asisten a servicios religiosos prolongados en la sinagoga. Estos servicios están llenos de oraciones especiales, himnos y plegarias de arrepentimiento. Se recita el Vidui, la confesión de los pecados, tanto a nivel individual como comunitario. Los judíos buscan la purificación y el perdón divino, y se comprometen a mejorar su comportamiento y ser mejores personas en el próximo año.",
    parrafo5:
      "El ayuno es una parte fundamental de Yom Kipur, simbolizando la renuncia a los placeres físicos para centrarse en la purificación espiritual. El abstenerse de alimentos y bebidas durante este día también representa la humildad y la vulnerabilidad ante Dios. El ayuno brinda una oportunidad para enfocarse en la oración, la reflexión profunda y la búsqueda de una mayor cercanía con lo sagrado.",
  },
  // Sucot
  {
    id: 3,
    festividad: "Sucot",
    resumen: "Fiesta de las cabañas",
    descripcion:
      "Sucot es una festividad que celebra la protección divina durante el éxodo del pueblo judío de Egipto. Se construyen cabañas temporales llamadas sucot y se realizan comidas festivas en ellas. Es un tiempo de alegría, gratitud y generosidad hacia los demás.",
    midot: ["Alegría", "Gratitud", "Generosidad"],
    energia: "Energía de alegría y agradecimiento",
    url: "fiestas/fiesta3.JPG",
    frase: "",
    parrafo1:
      "Sucot, también conocida como la Fiesta de los Tabernáculos, es una festividad importante en el calendario hebreo que se celebra justo después de Yom Kipur. Esta festividad tiene una profunda conexión con la historia y la tradición judía. Durante Sucot, se construyen y se habita temporalmente las sucot, que son estructuras cubiertas de ramas y follaje que simbolizan las frágiles moradas en las que vivieron los israelitas durante su travesía por el desierto después de salir de Egipto.",
    parrafo2:
      "La celebración de Sucot tiene múltiples significados. En primer lugar, representa la gratitud y la conmemoración de la protección divina y la provisión durante el tiempo en el desierto. Al habitar en las sucot, los judíos reconocen la fragilidad de la existencia humana y la dependencia de Dios para sustento y protección. Sucot también es conocida como Zman Simjateinu, el tiempo de nuestra alegría, y se considera una festividad de regocijo y felicidad.",
    parrafo3:
      "Durante Sucot, se realizan varias prácticas tradicionales. Se lleva a cabo una ceremonia especial llamada Lulav y Etrog, en la que se sostienen y se agitan un conjunto de ramas de palma, sauce y mirto junto con un fruto de cidro llamado etrog. Estos elementos representan diferentes aspectos de la creación y se considera una forma de adoración a Dios.",
    parrafo4:
      "Además, Sucot es un tiempo de reunión familiar y comunitaria. Se comparten comidas festivas en las sucot, se cantan canciones y se realizan danzas. También se lee el libro de Kohelet (Eclesiastés) en la sinagoga, que reflexiona sobre la fugacidad de la vida y la importancia de encontrar significado y alegría en las cosas simples.",
    parrafo5:
      "En resumen, Sucot es una festividad que conmemora la protección divina y la provisión durante el tiempo en el desierto. Al habitar en las sucot, los judíos reconocen su dependencia de Dios y expresan gratitud. Sucot es un tiempo de regocijo y alegría, donde se realizan prácticas como la ceremonia de Lulav y Etrog. Además, se enfatiza la importancia de la reunión familiar y comunitaria, compartiendo comidas y celebrando la vida.",
  },
  // Shemini Atzeret
  {
    id: 4,
    festividad: "Shemini Atzeret",
    resumen: "Octavo día de asamblea",
    descripcion:
      "Shemini Atzeret es un día de reunión adicional que sigue a Sucot. Es un día de oración especial por lluvia y una despedida de las festividades. Se recita la plegaria de Geshem (lluvia) y se disfruta de comidas festivas en familia.",
    midot: ["Plegaria", "Agradecimiento", "Renovación espiritual"],
    energia: "Energía de recogimiento y conexión con lo divino",
    url: "fiestas/",
    frase: "",
    parrafo1:
      "Shemini Atzeret es una festividad judía que se celebra al final de la festividad de Sucot, marcando un momento especial de reflexión y conexión espiritual. Esta festividad se destaca por su singularidad, ya que marca el octavo día después de Sucot, pero se considera una festividad separada en sí misma.",
    parrafo2:
      "Durante Shemini Atzeret, se llevan a cabo rituales especiales en las sinagogas, donde se recitan oraciones adicionales y se busca una mayor proximidad con lo divino. Es un día de descanso y reflexión, un momento para detenernos y contemplar las bendiciones recibidas durante Sucot.",
    parrafo3:
      "Una característica distintiva de Shemini Atzeret es la oración especial por la lluvia, que se realiza en Israel. Esta oración simboliza el final de la temporada de lluvias y el comienzo de un nuevo ciclo agrícola. Los fieles expresan su gratitud y piden bendiciones para asegurar una buena temporada de crecimiento y abundancia.",
    parrafo4:
      "Además, Shemini Atzeret coincide con Simjat Torá, una festividad en la cual se completa la lectura anual de la Torá y se comienza de inmediato una nueva lectura. Este momento es motivo de gran alegría y regocijo, donde se llevan a cabo danzas y procesiones con los rollos de la Torá en las sinagogas. Se celebra la importancia y la vitalidad de la Torá en la vida judía, renovando el compromiso con el estudio y la aplicación de sus enseñanzas.",
    parrafo5:
      "En resumen, Shemini Atzeret es una festividad que marca la conclusión de Sucot y el comienzo de Simjat Torá. Es un momento de reflexión, conexión espiritual y renovación. A través de los rituales especiales y las oraciones, los judíos expresan su gratitud, buscan bendiciones y renuevan su compromiso con la Torá. Simjat Torá, con sus celebraciones alegres, destaca la importancia de la lectura de la Torá y la conexión con la sabiduría divina. Ambas festividades nos invitan a reflexionar sobre nuestras bendiciones, fortalecer nuestra conexión espiritual y renovar nuestro compromiso con los valores y enseñanzas de la tradición judía.",
  },
  // Simjat Torá
  {
    id: 5,
    festividad: "Simjat Torá",
    resumen: "Regocijo de la Torá",
    descripcion:
      "Simjat Torá celebra el final y el comienzo anual de la lectura de la Torá. Es un día de alegría, danza y estudio de la Torá. Se lleva a cabo una Hakafot, procesión con los rollos de la Torá alrededor de la sinagoga, y se celebran fiestas y festivales en honor a la Torá.",
    midot: ["Alegría", "Estudio de la Torá", "Celebración"],
    energia: "Energía de regocijo y renovación espiritual",
    url: "fiestas/fiesta3",
    frase: "",
    parrafo1:
      "Simjat Torá es una festividad judía que se celebra al final de la festividad de Sucot. Literalmente significa alegría de la Torá, y es un momento de gran regocijo y celebración en la comunidad judía. Durante Simjat Torá, se completa la lectura anual de la Torá y se comienza de inmediato una nueva lectura.",
    parrafo2:
      "La festividad de Simjat Torá es una ocasión especial para honrar y celebrar la Torá, el libro sagrado del judaísmo. Los rollos de la Torá son sacados de los arcones y se llevan en procesión alrededor de la sinagoga. Las personas bailan y cantan alrededor de los rollos, expresando su alegría y gratitud por la sabiduría contenida en la Torá.",
    parrafo3:
      "Durante Simjat Torá, se enfatiza la importancia del estudio y la enseñanza de la Torá. Se realizan lecturas adicionales de porciones selectas de la Torá, y las comunidades judías se reúnen para escuchar enseñanzas y reflexionar sobre los mensajes de la Torá. Es un momento de renovación espiritual y conexión con las enseñanzas y los valores del judaísmo.",
    parrafo4:
      "Además de la celebración en la sinagoga, Simjat Torá se celebra también en los hogares judíos. Las familias se reúnen para estudiar la Torá, cantar canciones festivas y compartir comidas especiales. Es una oportunidad para transmitir el amor y el respeto por la Torá a las generaciones futuras.",
    parrafo5:
      "En resumen, Simjat Torá es una festividad de gran alegría y celebración en la que se completa y se reinicia la lectura anual de la Torá. Es un momento de honrar y reflexionar sobre las enseñanzas sagradas, y de renovar nuestro compromiso con el estudio y la práctica de la Torá. Tanto en la sinagoga como en los hogares, se celebra con danzas, cánticos y reuniones familiares, creando un ambiente de alegría y conexión espiritual en toda la comunidad judía.",
  },
  // Janucá
  {
    id: 6,
    festividad: "Janucá",
    resumen: "Fiesta de las luces",
    descripcion:
      "Janucá conmemora el milagro del aceite que duró ocho días en el Templo de Jerusalén. Se encienden las velas de la januquiá cada noche, se juega con el dreidel y se disfrutan comidas fritas en honor al aceite milagroso. Es un tiempo de esperanza, resistencia y celebración.",
    midot: ["Esperanza", "Resistencia", "Celebración"],
    energia: "Energía de milagro y libertad",
    url: "fiestas/fiesta4.JPG",
    frase: "",
    parrafo1:
      'Sucot, también conocida como la Fiesta de los Tabernáculos, es una festividad importante en el calendario hebreo que se celebra justo después de Yom Kipur. Esta festividad tiene una profunda conexión con la historia y la tradición judía. Durante Sucot, se construyen y se habita temporalmente las "sucot", que son estructuras cubiertas de ramas y follaje que simbolizan las frágiles moradas en las que vivieron los israelitas durante su travesía por el desierto después de salir de Egipto.',
    parrafo2:
      'La celebración de Sucot tiene múltiples significados. En primer lugar, representa la gratitud y la conmemoración de la protección divina y la provisión durante el tiempo en el desierto. Al habitar en las sucot, los judíos reconocen la fragilidad de la existencia humana y la dependencia de Dios para sustento y protección. Sucot también es conocida como "Zman Simjateinu", el tiempo de nuestra alegría, y se considera una festividad de regocijo y felicidad.',
    parrafo3:
      'Durante Sucot, se realizan varias prácticas tradicionales. Se lleva a cabo una ceremonia especial llamada "Lulav y Etrog", en la que se sostienen y se agitan un conjunto de ramas de palma, sauce y mirto junto con un fruto de cidro llamado etrog. Estos elementos representan diferentes aspectos de la creación y se considera una forma de adoración a Dios.',
    parrafo4:
      "Además, Sucot es un tiempo de reunión familiar y comunitaria. Se comparten comidas festivas en las sucot, se cantan canciones y se realizan danzas. También se lee el libro de Kohelet (Eclesiastés) en la sinagoga, que reflexiona sobre la fugacidad de la vida y la importancia de encontrar significado y alegría en las cosas simples.",
  },
  // Shivá Asar B'Tamuz
  {
    id: 14,
    festividad: "Shivá Asar B'Tamuz",
    resumen: "17 de Tamuz",
    descripcion:
      "Shivá Asar B'Tamuz es un ayuno menor que conmemora el día en que las murallas de Jerusalén fueron penetradas antes de la destrucción del Templo. Es un momento para reflexionar sobre nuestra conexión con la Tierra de Israel y el Templo.",
    midot: ["Reflexión", "Arrepentimiento", "Ayuno"],
    energia: "Energía de introspección y trascendencia",
    url: "fiestas/",
    frase: "",
    parrafo1:
      "Shivá Asar B'Tamuz es una fecha significativa en el calendario judío que marca el inicio de un período de duelo y ayuno en preparación para Tishá B'Av, la conmemoración de la destrucción del Templo de Jerusalén. Se celebra el 17 de Tamuz y marca el comienzo de un período de tres semanas de introspección y reflexión.",
    parrafo2:
      "Durante Shivá Asar B'Tamuz, los judíos conmemoran una serie de eventos trágicos en la historia judía. Entre ellos se incluye la ruptura de las tablas de la Ley por parte de Moisés al presenciar la adoración del becerro de oro, y la penetración de las murallas de Jerusalén antes de la destrucción del Templo.",
    parrafo3:
      "Esta festividad nos invita a reflexionar sobre la importancia de la conexión espiritual y la necesidad de arrepentimiento y reparación. Se realiza un ayuno desde el amanecer hasta el anochecer como una forma de expresar el luto y la tristeza por los eventos ocurridos.",
    parrafo4:
      "Durante el período de tres semanas que sigue a Shivá Asar B'Tamuz, se observan tradiciones de duelo, como abstenerse de celebraciones festivas y de cortarse el cabello o las uñas. También se intensifican las oraciones y el estudio de textos sagrados para fortalecer la conexión espiritual.",
    parrafo5:
      "En resumen, Shivá Asar B'Tamuz es una fecha que marca el comienzo de un período de duelo y reflexión en preparación para Tishá B'Av. Es un momento para recordar eventos trágicos en la historia judía y reflexionar sobre nuestra conexión con lo divino. A través del ayuno y las prácticas de duelo, nos invitamos a buscar el arrepentimiento y fortalecer nuestra relación con lo sagrado en preparación para Tishá B'Av.",
  },
  // Purim
  {
    id: 7,
    festividad: "Purim",
    resumen: "Fiesta de la salvación",
    descripcion:
      "Purim celebra la salvación del pueblo judío en Persia. Se lee el Libro de Ester, se intercambian regalos y se celebra con disfraces y alegría. Es un momento de alegría, valentía y generosidad hacia los demás.",
    midot: ["Alegría", "Valentía", "Generosidad"],
    energia: "Energía de alegría y coraje",
    url: "fiestas/fiesta5.JPG",
    frase: "",
    parrafo1:
      "Purim es una festividad judía que se celebra anualmente en el mes de Adar, conmemorando un evento histórico descrito en el Libro de Ester. Esta festividad es conocida por su ambiente alegre y festivo, y es considerada una de las festividades más alegres del calendario hebreo.",
    parrafo2:
      "La historia de Purim relata cómo Ester, una joven judía, se convirtió en reina del Imperio Persa y junto con su tío Mardoqueo, frustraron un complot para exterminar a los judíos tramado por el malvado Hamán. En Purim, los judíos celebran su salvación y la derrota de sus enemigos.",
    parrafo3:
      'Durante Purim, se llevan a cabo varias tradiciones y costumbres. Se lee el Libro de Ester en la sinagoga, y cada vez que se menciona el nombre de Hamán, se realiza ruidos y se agitan matracas para "borrar" su nombre. Además, se intercambian regalos de alimentos y se realizan donaciones a los necesitados, como un acto de caridad y solidaridad',
    parrafo4:
      "Una de las principales tradiciones de Purim es el disfrazarse. Tanto los niños como los adultos se visten con disfraces y se celebra un desfile de disfraces, donde se pueden ver personajes de la historia de Ester, así como disfraces creativos y divertidos. Esta costumbre de disfrazarse tiene como propósito recordar que Dios ocultó su presencia en la historia de Purim, y también agrega un elemento lúdico y festivo a la celebración.",
    parrafo5:
      'La lectura del Libro de Ester también es acompañada de festivales y banquetes, donde se disfrutan de comidas tradicionales, como los "hamantaschen", unos deliciosos pasteles en forma de triángulo rellenos de diferentes sabores, que simbolizan los sombreros de Hamán.',
  },
  // Pesaj
  {
    id: 8,
    festividad: "Pesaj",
    resumen: "Fiesta de la liberación",
    descripcion:
      "Pesaj conmemora la liberación del pueblo judío de la esclavitud en Egipto. Durante la festividad se realiza el Seder, una cena ritual en la que se cuenta la historia de la salida de Egipto. Es un tiempo de gratitud, libertad y renovación espiritual.",
    midot: ["Gratitud", "Libertad", "Renacimiento"],
    energia: "Energía de gratitud y libertad",
    url: "fiestas/fiesta6.JPG",
    frase: "",
    parrafo1:
      "Pesaj, también conocida como la Pascua judía, es una festividad de gran importancia en el calendario hebreo. Se celebra durante ocho días en conmemoración del éxodo de los judíos de la esclavitud en Egipto y su liberación hacia la libertad.",
    parrafo2:
      "La historia de Pesaj se relata en el libro del Éxodo, donde se narra cómo el pueblo judío fue liberado de la opresión egipcia después de que Dios enviara diez plagas sobre Egipto. La última plaga fue la muerte de los primogénitos, pero los hogares judíos fueron protegidos al marcar las puertas con sangre de cordero. Esto llevó al faraón a liberar al pueblo judío y permitirles partir hacia la libertad.",
    parrafo3:
      "Uno de los aspectos más destacados de Pesaj es el Seder, una cena ceremonial que se celebra en la primera noche de la festividad. Durante el Seder, se sigue un orden específico de pasos y se leen pasajes del libro del Éxodo. Se consumen alimentos simbólicos, como el matzá (pan ácimo) que representa la prisa con la que los judíos tuvieron que abandonar Egipto, y el maror (hierbas amargas) que simbolizan la amargura de la esclavitud.",
    parrafo4:
      "Además de los rituales del Seder, durante Pesaj los judíos se abstienen de comer productos leudados (jametz) y consumen exclusivamente alimentos sin levadura. Esto conmemora la prisa con la que los judíos tuvieron que dejar Egipto, ya que no tuvieron tiempo de dejar fermentar el pan. Durante la festividad, se realizan limpiezas minuciosas en los hogares para eliminar cualquier rastro de jametz.",
    parrafo5:
      "Pesaj es un tiempo para recordar la liberación del pueblo judío y reflexionar sobre el significado de la libertad. También es una festividad que celebra la continuidad y la preservación de la identidad judía a lo largo de la historia. Se transmiten las historias y tradiciones de Pesaj de generación en generación, y se enfatiza la importancia de transmitir estos valores a los hijos.",
  },
  // Pésaj Sheni
  {
    id: 12,
    festividad: "Pésaj Sheni",
    resumen: "Segunda Pascua",
    descripcion:
      "Pésaj Sheni es una oportunidad para aquellos que no pudieron celebrar Pésaj en el momento designado. Es un tiempo para corregir o completar cualquier aspecto que haya sido dejado de lado. Se come matzá y se realiza el Seder de Pésaj Sheni.",
    midot: ["Segunda oportunidad", "Redención", "Corrección"],
    energia: "Energía de rectificación y completitud",
    url: "fiestas/",
    frase: "",
    parrafo1:
      "Pésaj Sheni, que significa 'la segunda Pascua', es una festividad judía que se celebra el decimocuarto día del mes hebreo de Iyar. Esta festividad se estableció como una oportunidad para aquellos que no pudieron participar en la celebración de Pésaj en su tiempo designado.",
    parrafo2:
      "La festividad de Pésaj Sheni tiene su origen en el relato bíblico en el que ciertas personas no pudieron ofrecer el sacrificio de Pascua en el Templo debido a la impureza ritual o estar lejos en un viaje. En respuesta a esta situación, se instituyó Pésaj Sheni como una segunda oportunidad para cumplir con este mandato sagrado.",
    parrafo3:
      "Durante Pésaj Sheni, aquellos que no pudieron celebrar Pésaj en su momento designado tienen la oportunidad de hacerlo. Se llevan a cabo rituales similares a los de Pésaj, incluyendo la ingesta de matzá (pan sin levadura) y la reflexión sobre la historia del Éxodo y la libertad.",
    parrafo4:
      "Pésaj Sheni también nos enseña sobre la importancia de las segundas oportunidades y la capacidad de rectificar nuestros errores. Nos recuerda que siempre hay una oportunidad para redimirnos y volver al camino correcto, incluso si hemos fallado previamente. Es un recordatorio de la misericordia divina y la posibilidad de renovación espiritual.",
    parrafo5:
      "En resumen, Pésaj Sheni es una festividad que permite a aquellos que no pudieron celebrar Pésaj en su tiempo designado tener una segunda oportunidad. Es un momento para reflexionar sobre la importancia de las segundas oportunidades y la capacidad de rectificar nuestros errores. Pésaj Sheni nos enseña sobre la misericordia divina y la posibilidad de renovación espiritual, recordándonos que siempre hay una oportunidad para volver al camino correcto.",
  },
  // Lag BaOmer
  {
    id: 13,
    festividad: "Lag BaOmer",
    resumen: "33º día de la cuenta del Omer",
    descripcion:
      "Lag BaOmer es un día de celebración durante el período de la cuenta del Omer. Se conmemora el fin de una plaga que afectó a los estudiantes de rabí Akiva y se celebra con fogatas, música y actividades al aire libre.",
    midot: ["Alegría", "Unidad", "Estudio de la Torá"],
    energia: "Energía de alegría y conexión espiritual",
    url: "fiestas/",
    frase: "",
    parrafo1:
      "Lag BaOmer es una festividad judía que se celebra el día 33 del conteo del Omer, un período de 49 días que comienza en la festividad de Pésaj y culmina en Shavuot. Es un día de alegría y celebración que se destaca en medio de este período de duelo y restricciones.",
    parrafo2:
      "La festividad de Lag BaOmer conmemora varios eventos significativos en la historia judía. Uno de ellos es el cese de una plaga que afectaba a los estudiantes del rabino Akiva, un destacado sabio judío. Otro evento importante asociado con Lag BaOmer es la revelación de los misterios ocultos de la Torá por parte del rabino Shimon bar Yojai.",
    parrafo3:
      "Durante Lag BaOmer, se llevan a cabo diversas celebraciones y tradiciones. Una de las prácticas más conocidas es la de encender hogueras, simbolizando la luz y el conocimiento que el rabino Shimon bar Yojai compartió. Además, es común realizar actividades al aire libre, como picnics, deportes y juegos, especialmente para los niños.",
    parrafo4:
      "Esta festividad también tiene una dimensión espiritual y simbólica. Lag BaOmer representa un momento de renovación y resurgimiento después de un período de duelo. Es un día en el que se celebra la alegría y se fortalece el espíritu. También se considera un momento propicio para la reflexión sobre la importancia del estudio de la Torá y el respeto a los sabios judíos.",
    parrafo5:
      "En resumen, Lag BaOmer es una festividad judía que marca un momento de alegría y celebración en el conteo del Omer. Conmemora eventos significativos en la historia judía y representa un tiempo de renacimiento y renovación espiritual. A través de hogueras, actividades al aire libre y reflexiones sobre la sabiduría de la Torá, Lag BaOmer nos invita a disfrutar de la vida, fortalecer nuestra conexión con la tradición judía y buscar la luz y el conocimiento en nuestras vidas.",
  },
  // Shavuot
  {
    id: 9,
    festividad: "Shavuot",
    resumen: "Fiesta de la entrega de la Torá",
    descripcion:
      "Shavuot conmemora la entrega de la Torá en el Monte Sinaí. Es un tiempo de estudio y celebración de la sabiduría divina. Se leen los Diez Mandamientos y se disfrutan productos lácteos en honor a la abundancia de la Tierra de Israel.",
    midot: ["Estudio de la Torá", "Espiritualidad", "Celebración"],
    energia: "Energía de sabiduría y conexión espiritual",
    url: "fiestas/fiesta7.JPG",
    frase: "",
    parrafo1:
      "Shavuot es una festividad judía que se celebra siete semanas después de Pesaj, marcando el fin de la cuenta del Omer. Es una festividad que conmemora la entrega de la Torá en el monte Sinaí y marca un momento crucial en la historia judía y en la relación entre Dios y el pueblo judío.",
    parrafo2:
      "La festividad de Shavuot tiene múltiples significados. En primer lugar, se celebra la revelación de la Torá en el monte Sinaí, cuando Moisés recibió los Diez Mandamientos y el conjunto de leyes y enseñanzas divinas. Es un momento de renovación de la alianza entre Dios y el pueblo judío, y se considera una ocasión de gran alegría y gratitud.",
    parrafo3:
      "Shavuot también está asociada con la cosecha de trigo en la antigua tierra de Israel. En ese tiempo, se llevaban las primicias de los frutos de la tierra al Templo en Jerusalén como ofrendas a Dios. Esta conexión con la agricultura y la abundancia se refleja en las costumbres de Shavuot, donde se decoran las sinagogas y los hogares con flores y plantas.",
    parrafo4:
      "Una de las tradiciones más conocidas de Shavuot es el estudio de la Torá durante toda la noche. Esta costumbre se conoce como  Tikún Leil Shavuot y se realiza en comunidades judías de todo el mundo. Se llevan a cabo sesiones de estudio en grupo, conferencias y discusiones sobre temas de la Torá, fomentando el aprendizaje y la profundización en los textos sagrados.",
    parrafo5:
      "En resumen, Shavuot es una festividad que conmemora la entrega de la Torá en el monte Sinaí y marca un momento crucial en la historia judía. Se celebra con alegría y gratitud, destacando el estudio de la Torá y la renovación de la alianza entre Dios y el pueblo judío. Shavuot también está asociada con la cosecha de trigo y se caracteriza por el estudio de la Torá durante toda la noche y la preparación de platos lácteos. Es un tiempo para celebrar la sabiduría y la conexión espiritual con la Torá.",
  },
  // Tishá B'Av
  {
    id: 10,
    festividad: "Tishá B'Av",
    resumen: "Día de luto",
    descripcion:
      "Tishá B'Av es un día de luto y ayuno que conmemora la destrucción del Templo de Jerusalén y otras tragedias históricas. Se lee el Libro de las Lamentaciones y se reflexiona sobre la importancia de reconstruir y preservar nuestra herencia espiritual.",
    midot: ["Reflexión", "Arrepentimiento", "Luto"],
    energia: "Energía de introspección y duelo",
    url: "fiestas/",
    frase: "",
    parrafo1:
      "Tishá B'Av es una festividad judía que conmemora la destrucción del Templo de Jerusalén. Se celebra el noveno día del mes hebreo de Av y es considerado un día de duelo y luto en la historia judía.",
    parrafo2:
      "Durante Tishá B'Av, los judíos realizan ayuno y se abstienen de ciertas actividades como bañarse, usar calzado de cuero y tener relaciones íntimas. Es un día de introspección y reflexión sobre los errores y pecados que llevaron a la destrucción del Templo y las tragedias que han ocurrido a lo largo de la historia judía.",
    parrafo3:
      "La festividad está marcada por la lectura del libro de Lamentaciones, que relata la destrucción de Jerusalén y el sufrimiento del pueblo judío. Se recitan elegías y se llevan a cabo servicios religiosos en las sinagogas para recordar la tragedia y lamentar las pérdidas.",
    parrafo4:
      "Tishá B'Av también es un día en el que se reflexiona sobre la necesidad de rectificar los errores del pasado y buscar la reconciliación y la reconstrucción. Se encienden velas en memoria de los caídos y se realizan actos de caridad y justicia como una forma de honrar la memoria de quienes sufrieron en tiempos pasados.",
    parrafo5:
      "Aunque Tishá B'Av es un día de tristeza y lamento, también representa la esperanza de un futuro mejor. Se espera que algún día el Templo sea reconstruido y que el pueblo judío pueda vivir en paz y armonía. La festividad nos recuerda la importancia de aprender de la historia y trabajar por un mundo mejor y más justo.",
  },
  // Tu B'Shvat
  {
    id: 11,
    festividad: "Tu B'Shvat",
    resumen: "Año nuevo de los árboles",
    descripcion:
      "Tu B'Shvat es el año nuevo de los árboles y se celebra la renovación de la naturaleza. Es un momento para reflexionar sobre nuestra conexión con el medio ambiente y promover la preservación de la Tierra. Se come frutas y se realiza la ceremonia del Seder de Tu B'Shvat.",
    midot: [
      "Conexión con la naturaleza",
      "Gratitud",
      "Cuidado del medio ambiente",
    ],
    energia: "Energía de conexión con la naturaleza y renovación",
    url: "fiestas/",
    frase: "",
    parrafo1:
      "Tu B'Shvat es una festividad judía que celebra el Año Nuevo de los Árboles. Se celebra el décimo quinto día del mes hebreo de Shvat y marca el despertar de la primavera y el inicio de un nuevo ciclo de crecimiento y renovación en la naturaleza.",
    parrafo2:
      "Durante Tu B'Shvat, los judíos honran y aprecian los árboles y la importancia de la naturaleza en sus vidas. Se realiza un enfoque especial en la tierra y la agricultura, y se reflexiona sobre la responsabilidad de cuidar y preservar el medio ambiente.",
    parrafo3:
      "La festividad se celebra consumiendo una variedad de frutas y alimentos relacionados con la tierra, como nueces, frutas secas y productos derivados de árboles. Es un momento para agradecer por las bendiciones de la tierra y reconocer la importancia de la sustentabilidad y la conservación.",
    parrafo4:
      "Durante Tu B'Shvat, también se llevan a cabo actividades de plantación de árboles, tanto en tierra como en macetas, como una forma de contribuir al crecimiento y la belleza de la naturaleza. Estas acciones simbólicas nos recuerdan la importancia de cuidar nuestro entorno y ser buenos administradores de los recursos naturales.",
    parrafo5:
      "Tu B'Shvat también tiene un significado espiritual, representando el potencial de crecimiento y transformación personal. Al igual que los árboles, tenemos la capacidad de renovarnos y florecer en nuestra vida espiritual. La festividad nos invita a reflexionar sobre nuestras acciones y a comprometernos a vivir de manera armoniosa con la naturaleza y a cuidar nuestro entorno para las generaciones futuras.",
  },

  //  Shabat
  {
    id: 99,
    festividad: "Shabbat",
    resumen: "Dia de descanso",
    descripcion:
      "Shabbat es una festividad sagrada en el judaísmo que se celebra semanalmente. Es un momento para renovar la conexión con lo divino y fortalecer los lazos familiares y comunitarios.",
    midot: [],
    energia: "",
    url: "fiestas/fiesta8.JPG",
    frase: "La consagración de la vida",
    parrafo1:
      "Shabbat, el día de descanso semanal en el judaísmo, es una festividad sagrada que se celebra desde el anochecer del viernes hasta la noche del sábado. Es un momento dedicado al reposo, la reflexión espiritual y la conexión con la familia y la comunidad.",
    parrafo2:
      "El Shabbat tiene sus raíces en la narrativa bíblica de la creación, donde Dios descansó en el séptimo día después de crear el mundo. Se considera un mandamiento divino observar y santificar el Shabbat como un día de descanso y adoración",
    parrafo3:
      "Durante el Shabbat, se abstiene de realizar trabajos y actividades que involucren esfuerzo creativo. En cambio, se dedica tiempo a la oración, al estudio de la Torá y a la reflexión espiritual. La sinagoga es un lugar central para la observancia del Shabbat, donde se lleva a cabo el servicio religioso y se recita el Kidush, una bendición sobre el vino que marca el comienzo del Shabbat.",
    parrafo4:
      'La comida desempeña un papel importante en la celebración del Shabbat. Se lleva a cabo una comida festiva llamada "Shabbat HaMalka" (Reina del Shabbat) en honor a la reina que representa la Shejiná, la presencia divina. Se bendice el pan trenzado especial llamado "challah" y se comparte una comida en familia, a Fiestado con platos tradicionales y especiales para marcar la ocasión.',
    parrafo5:
      "El Shabbat también es un tiempo para la unión familiar y comunitaria. Se suele pasar tiempo de calidad con la familia, disfrutando de actividades recreativas, cantando canciones tradicionales y compartiendo historias. La comunidad se reúne en la sinagoga y en eventos sociales para celebrar y fortalecer los lazos comunitarios.",
  },
];

export default tablaFestividades;

import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import { List } from "bootstrap-4-react";
 
import tablaParashot from "../Tablas/Tablaparashot";
const Parashot = () => {
  return (
    <section Parashot>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-3  text-center">
        <h3> Parashot de la Semana</h3>
      </div>
      <Container>
        <p>
          Las parashot de la semana, que son las porciones semanales de la Torá
          que se leen en la sinagoga, pueden ser utilizadas como una herramienta
          valiosa para trabajar en el desarrollo de los midot.
        </p>
        <p>
          Cada parashá nos brinda relatos, enseñanzas y mandamientos que
          contienen lecciones éticas y morales. Al estudiar y reflexionar sobre
          las parashot, podemos extraer principios y valores universales que nos
          ayudan a cultivar nuestros midot.
        </p>
        <p>
          Estas son algunas formas en las que una parashá puede servirnos en
          nuestro trabajo de midot:
        </p>
        <ul>
          <li>
            <p>
              Autoexamen: Las historias y los personajes de la parashá pueden
              servir como espejo para mirar dentro de nosotros mismos. Podemos
              identificar rasgos de carácter y comportamientos en los personajes
              y reflexionar sobre cómo se relacionan con nuestra propia vida.
              Esto nos permite evaluar nuestras acciones, reconocer áreas de
              mejora y trabajar en el desarrollo de los midot.
            </p>
          </li>
          <li>
            <p>
              Inspiración: Las enseñanzas y mandamientos de la parashá pueden
              inspirarnos a vivir de acuerdo con valores éticos elevados. Pueden
              motivarnos a cultivar virtudes como la honestidad, la compasión,
              la justicia y la bondad. A través del estudio y la reflexión de la
              parashá, podemos obtener ideas y orientación sobre cómo aplicar
              esos principios en nuestra vida diaria.
            </p>
          </li>
          <li>
            <p>
              Conexión con la comunidad: La lectura semanal de la misma parashá
              en comunidades judías de todo el mundo crea una conexión especial
              entre las personas. Al estudiar y discutir la parashá con otros,
              podemos compartir perspectivas, aprender de diferentes puntos de
              vista y fomentar el crecimiento personal y comunitario. Esta
              interacción fortalece nuestra conexión con los demás y nos permite
              desarrollar midot como el respeto, la empatía y la colaboración.
            </p>
          </li>
          <li>
            <p>
              Autodisciplina: Al comprometernos a estudiar y reflexionar sobre
              la parashá semanalmente, desarrollamos la autodisciplina y la
              constancia en nuestro trabajo de midot. A través de la rutina de
              dedicar tiempo y esfuerzo al estudio de la Torá, fortalecemos
              nuestra capacidad de mantenernos comprometidos con nuestros
              valores y metas éticas a largo plazo.
            </p>
          </li>
        </ul>
        <p>
          En resumen, las parashot de la semana son una valiosa herramienta para
          nuestro trabajo de midot. Nos brindan enseñanzas, inspiración y la
          oportunidad de reflexionar sobre nuestra propia conducta. Al estudiar
          y aplicar los principios éticos y morales de la parashá, podemos
          fortalecer nuestros midot y vivir una vida más virtuosa y
          significativa.
        </p>
        <Row xs={1} md={2} lg={3} className="g-10 text-center">
          {tablaParashot.map((p, idx) => (
            <Col p={p} key={idx}>
              <Card style={{ width: "22rem", height: "20rem" }} className="m-3">
               
                <Card.Body>
                  <Card.Title>{p.nombre}</Card.Title>
                  <Card.Text>{p.descripcion}</Card.Text>
                </Card.Body>
                <Card.Footer>
                <Card.Text><h6> Midot para trabajar</h6> </Card.Text>
                </Card.Footer>
                <Card.Footer >
                  <List unstyled>
                    {p.midot.map((q, idx1) => (
                      <List.Item> {q}</List.Item>
                    ))}
                  </List>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section >
  );
};

export default Parashot;

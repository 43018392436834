import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

import tablaSefirot from "../Tablas/Tablasefirot";
const Sefirot = () => {

   return (
    <section Sefirot>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h3> Las 10 Sefirot</h3>
      </div>
      <Container>
        <Row xs={1} md={1} lg={2} className="g-10 text-center">
          {tablaSefirot.map((p, idx) => (
            <Col p={p} key={idx}>
              <Card style={{ width: "25rem", height: "10rem" }} className="m-3">
                <Card.Body>
                  <Card.Title>{p.nombre}</Card.Title>  
                  <Card.Title>{p.hebreo}</Card.Title>  
                  <Card.Text>{p.descripcion}</Card.Text>             
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};


export default Sefirot;
let tablaMeses = [
  //Nisan - Aries
  {
    id: 8,
    español: "Abril",
    hebreo: "נִיסָן",
    nombre: "Nisán",
    descripcion:
      "Nisán es el mes de la salida de Egipto y la festividad de Pesaj, que conmemora la liberación del pueblo judío de la esclavitud.",
    midot: ["Libertad", "Gratitud", "Renacimiento"],
    festividades: ["Pesaj"],
    url: "signos/nisan.PNG",
    parrafo1:
      "El mes de Nisán es un mes significativo en el calendario hebreo, que generalmente cae en la primavera, entre marzo y abril. Es conocido por ser el primer mes del año religioso judío y marca el comienzo de las festividades más importantes del judaísmo, como la Pascua (Pesaj). Durante este mes, se celebra la liberación de los hebreos de la esclavitud en Egipto, conmemorando el éxodo liderado por Moisés. Las familias judías se reúnen para llevar a cabo rituales tradicionales, como la cena de Seder, en la cual se relata la historia de la liberación y se consumen alimentos simbólicos.",
    parrafo2:
      "Nisán es un mes lleno de simbolismo y significado espiritual para la comunidad judía. Además de la celebración de Pesaj, se considera un tiempo de renovación y liberación personal. Muchos aprovechan este mes para reflexionar sobre sus vidas, buscar la redención y el perdón, y liberarse de las cadenas del pasado. Es un momento en el que se enfatiza la importancia de la libertad, la gratitud y la conexión con la fe y la tradición.",
    parrafo3:
      "Además de su relevancia religiosa, el mes de Nisán también marca la transición hacia la primavera, un período de renacimiento y crecimiento en la naturaleza. Los campos y los jardines comienzan a florecer, y la vida vuelve a brotar después del invierno. Este aspecto estacional se refuerza con las prácticas agrícolas ancestrales que se asociaban con este mes, cuando se sembraban los campos y se preparaban para la cosecha que vendría más adelante.",
    parrafo4:
      "En resumen, el mes de Nisán es un tiempo especial en el calendario judío, donde se celebra la liberación de los hebreos, se fortalece la conexión con la fe y se busca la renovación personal. Es un período de reflexión, gratitud y celebración, tanto en el contexto religioso como en el natural.",
  },
  //Iyar - Tauro
  {
    id: 9,
    español: "Mayo",
    hebreo: "אִיָּר",
    nombre: "Iyar",
    descripcion:
      "En Iyar se celebra la festividad de Lag BaOmer y es un mes de preparación espiritual para Shavuot.",
    midot: ["Sanación", "Estudio de la Torá", "Conteo del Omer"],
    festividades: ["Lag BaOmer"],
    url: "signos/iyar.PNG",
    parrafo1:
      "Iyar es el segundo mes del calendario hebreo y generalmente cae en abril-mayo. Es un mes significativo en la tradición judía y se caracteriza por su conexión con la festividad de Lag Baomer. Durante este mes, se celebra el día 33 de Omer, una festividad en la cual se conmemora el fin de una plaga que afectó a los discípulos de un antiguo rabino. Lag Baomer se celebra con fuegos artificiales, danzas y actividades al aire libre.",

    parrafo2:
      "Iyar también se asocia con la temporada de la cuenta del Omer, una cuenta diaria de los días entre la Pascua y Shavuot. Durante este período, los judíos reflexionan sobre temas de crecimiento personal y espiritual. Además, Iyar es un momento para honrar la memoria de los soldados caídos en las Fuerzas de Defensa de Israel, en la conmemoración conocida como el Día de los Caídos.",

    parrafo3:
      "Este mes tiene un enfoque especial en la curación y la salud, y muchos judíos aprovechan este tiempo para ofrecer oraciones y bendiciones por la salud de sus seres queridos. Iyar también es conocido como un mes de oportunidad para renovar las energías y enfocarse en el autocuidado y el bienestar.",

    parrafo4:
      "En resumen, Iyar es un mes lleno de significado en el calendario hebreo. Es un tiempo de celebración, reflexión y honra a través de festividades como Lag Baomer y el Día de los Caídos. Además, se enfatiza la importancia de la salud y el bienestar personal durante este mes.",
  },
  //Sivan - Geminis
  {
    id: 10,
    español: "Junio",
    hebreo: "סִיוָן",
    nombre: "Siván",
    descripcion:
      "El mes de Siván incluye la festividad de Shavuot, cuando se conmemora la entrega de la Torá en el Monte Sinaí.",
    midot: ["Estudio de la Torá", "Espiritualidad", "Celebración"],
    festividades: ["Shavuot"],
    url: "signos/sivan.PNG",

    parrafo1:
      "Sivan es el tercer mes del calendario hebreo y generalmente cae en mayo-junio. Es conocido por ser el mes en el que se celebra la festividad de Shavuot, también conocida como la Fiesta de las Semanas. Durante Shavuot, los judíos conmemoran la entrega de la Torá en el monte Sinaí y celebran la cosecha de las primeras frutas.",

    parrafo2:
      "Además de Shavuot, Sivan es un mes de alegría y celebración. Se considera un tiempo propicio para el crecimiento espiritual y la conexión con la divinidad. Muchos judíos aprovechan este mes para estudiar textos sagrados, participar en rituales de oración y reflexionar sobre la importancia de la sabiduría y la enseñanza.",

    parrafo3:
      "Durante Sivan, también se recuerda el episodio bíblico del becerro de oro, en el cual el pueblo judío cayó en la idolatría mientras Moisés recibía los mandamientos en el monte Sinaí. Este incidente sirve como una advertencia sobre los peligros de la idolatría y la importancia de mantenerse fieles a los principios y enseñanzas de la fe judía.",

    parrafo4:
      "En resumen, Sivan es un mes de significado espiritual y celebración en el calendario hebreo. Se destaca por la festividad de Shavuot y se considera un tiempo propicio para el estudio, la conexión con lo divino y la reflexión sobre la importancia de los valores y enseñanzas judías.",
  },
  //Tamuz - Cancer
  {
    id: 11,
    español: "Julio",
    hebreo: "תַּמּוּז",
    nombre: "Tamuz",
    descripcion:
      "Un mes de introspección y arrepentimiento. Se observa el ayuno de Shiva Asar B'Tamuz, que marca la brecha en las murallas de Jerusalén.",
    midot: ["Reflexión", "Arrepentimiento", "Ayuno"],
    festividades: ["Shiva Asar B'Tamuz"],
    url: "signos/tamuz.PNG",
    parrafo1:
      "Tamuz es el cuarto mes del calendario hebreo y generalmente cae en junio-julio. En la tradición judía, Tamuz es un mes que conmemora eventos trágicos y de duelo. Se considera un tiempo de reflexión y lamento por la destrucción del Templo de Jerusalén y otras tragedias históricas.",
    parrafo2:
      "Durante Tamuz, se destaca el ayuno de Shiva Asar B'Tamuz, que marca el comienzo de un período de tres semanas conocido como Bein Hametzarim, o 'entre las estrecheces'. Durante estas tres semanas, que culminan con el ayuno de Tisha B'Av, los judíos reflexionan sobre la destrucción del Templo y otros eventos trágicos en la historia judía.",
    parrafo3:
      "Tamuz también es un mes de recordatorio de la importancia de la unidad y la reconciliación. Se reflexiona sobre las consecuencias de la división y se fomenta el acercamiento entre las personas. Además, se busca fortalecer la conexión con la fe y el retorno a los valores y enseñanzas judías.",
    parrafo4:
      "En resumen, Tamuz es un mes de duelo y reflexión en el calendario hebreo. Se conmemora la destrucción del Templo de Jerusalén y se reflexiona sobre la importancia de la unidad y la reconciliación. Durante este tiempo, los judíos buscan fortalecer su conexión con la fe y regresar a los valores fundamentales de la tradición judía.",
  },
  //Av - Leo
  {
    id: 12,
    nombre: "Av",
    español: "Agosto",
    hebreo: "אָב",
    descripcion:
      "Un mes que contiene el ayuno de Tishá B'Av, que conmemora la destrucción del Templo de Jerusalén y otras tragedias históricas.",
    midot: ["Luto", "Compasión", "Esperanza"],
    festividades: ["Tishá B'Av"],
    url: "signos/av.PNG",
    parrafo1:
      "Av es el quinto mes del calendario hebreo y generalmente cae en julio-agosto. Es un mes de dualidad en la tradición judía, ya que combina momentos de tristeza y luto con la esperanza de consuelo y redención futura.",
    parrafo2:
      "Durante Av, se conmemora el ayuno de Tisha B'Av, uno de los días más tristes del calendario judío. En este día, se recuerda la destrucción de los dos Templos de Jerusalén y otras tragedias históricas. Es un momento de luto y reflexión sobre las consecuencias de la división y la pérdida de la conexión con lo divino.",
    parrafo3:
      "A pesar de la tristeza asociada con Tisha B'Av, Av también es un mes que guarda la promesa de esperanza y consuelo. Se cree que en el futuro, este mes se transformará en un tiempo de celebración y alegría, ya que está relacionado con la redención final y la reconstrucción del Templo de Jerusalén.",
    parrafo4:
      "En resumen, Av es un mes de dualidad en el calendario hebreo. Se conmemora la destrucción de los Templos de Jerusalén y se reflexiona sobre las consecuencias de la división y la pérdida. Aunque es un tiempo de tristeza, Av también guarda la promesa de esperanza y redención futura, brindando consuelo en medio del luto.",
  },
  //Elul - Virgo
  {
    id: 13,
    nombre: "Elul",
    español: "Septiembre",
    hebreo: "אֱלוּל",
    descripcion:
      "El mes de Elul es un período de introspección y preparación espiritual antes de Rosh Hashaná y Yom Kipur.",
    midot: ["Autoreflexión", "Arrepentimiento", "Preparación"],
    festividades: [],
    url: "signos/elul.PNG",
    parrafo1:
      "Elul es el último mes del calendario hebreo y se encuentra aproximadamente en el período de agosto a septiembre. Es un mes de gran importancia en la tradición judía, ya que precede al mes de Tishrei, que incluye las festividades de Rosh Hashaná (Año Nuevo judío) y Yom Kipur (Día de la Expiación). Elul es considerado un tiempo de preparación y reflexión espiritual antes de estas festividades.",
    parrafo2:
      "Durante el mes de Elul, los judíos se dedican a una serie de prácticas religiosas, como el arrepentimiento (teshuvá), la búsqueda del perdón y la reconciliación. Es un período de introspección personal, en el que se reflexiona sobre las acciones del año pasado y se hacen esfuerzos para mejorar y corregir los errores cometidos. Muchos creyentes utilizan este tiempo para pedir perdón a aquellos a quienes han lastimado y para perdonar a quienes los han herido.",
    parrafo3:
      "Elul se caracteriza por ser un mes de reconciliación y de acercamiento a Dios. Los judíos recitan salmos especiales llamados 'Salmos de Elul' durante este tiempo, como una forma de conexión y elevación espiritual. Además, se sopla el shofar (un cuerno de carnero) todas las mañanas, excepto en Shabat, como una llamada a despertar y recordatorio de la importancia de la introspección y el arrepentimiento.",
    parrafo4:
      "En resumen, Elul es un mes de preparación y reflexión espiritual en el calendario judío. Es un período de introspección personal, arrepentimiento y reconciliación, donde los judíos buscan mejorar sus acciones y fortalecer su conexión con Dios antes de las festividades de Rosh Hashaná y Yom Kipur. Es un tiempo de renovación y búsqueda de perdón tanto hacia los demás como hacia uno mismo.",
  },
  //Tishrei - Libra
  {
    id: 1,
    español: "Octubre",
    hebreo: "תִּשׁרִי",
    nombre: "Tishrei",
    descripcion:
      "El primer mes del calendario hebreo. Es un mes de reflexión, arrepentimiento y celebración de Rosh Hashaná y Yom Kipur.",
    midot: ["Arrepentimiento", "Renovación espiritual", "Autodisciplina"],
    festividades: [
      "Rosh Hashaná",
      "Yom Kipur",
      "Sucot",
      "Shemini Atzeret",
      "Simjat Torá",
    ],
    url: "signos/tishrei.PNG",
    parrafo1:
      "Tishrei es el primer mes del calendario hebreo y generalmente cae en septiembre-octubre. Es un mes muy significativo en la tradición judía, ya que incluye varias festividades importantes, como Rosh Hashaná (Año Nuevo judío), Yom Kipur (Día de la Expiación) y Sucot (Fiesta de los Tabernáculos).",
    parrafo2:
      "Durante Rosh Hashaná, los judíos celebran el comienzo del año nuevo y se enfocan en la introspección, la reflexión y el arrepentimiento. Es un momento para evaluar las acciones del año pasado y establecer intenciones para el año venidero. Yom Kipur, por otro lado, es un día de ayuno y oración en el que se busca la expiación de los pecados y el perdón divino.",
    parrafo3:
      "Sucot es una festividad de alegría y gratitud en la que se conmemora la protección divina durante el éxodo de los hebreos en el desierto. Durante esta festividad, se construyen y se habitan enramadas temporales llamadas sucot, y se lleva a cabo un conjunto de rituales y ceremonias especiales.",
    parrafo4:
      "En resumen, Tishrei es un mes repleto de festividades y significado en el calendario hebreo. Desde Rosh Hashaná y Yom Kipur, donde se enfatiza la introspección y la búsqueda del perdón, hasta Sucot, una festividad de gratitud y alegría, Tishrei nos invita a reflexionar sobre nuestras vidas, a conectarnos con lo divino y a celebrar las bendiciones recibidas.",
  },
  //Jeshván - Escorpio
  {
    id: 2,
    español: "Noviembre",
    hebreo: "חֶשְׁוָן",
    nombre: "Jeshván",
    descripcion:
      "Un mes sin festividades, se considera un período de reflexión y preparación para el futuro.",
    midot: ["Paciencia", "Persistencia", "Autocontrol"],
    festividades: [],
    url: "signos/jeshvan.PNG",
    parrafo1:
      "Jeshván, también conocido como Marjeshván, es el octavo mes del calendario hebreo y generalmente cae en octubre-noviembre. Es uno de los pocos meses en el calendario judío que no tiene festividades o días sagrados específicos. Es un mes de transición, sin eventos históricos o rituales religiosos destacados.",
    parrafo2:
      "Después de los días intensos de las festividades de Tishrei, como Rosh Hashaná, Yom Kipur y Sucot, Jeshván se considera un tiempo de calma y recuperación. Es un período en el que los judíos retoman sus actividades diarias normales y se centran en el aspecto cotidiano de sus vidas.",
    parrafo3:
      "Aunque Jeshván no tiene festividades propias, algunos judíos aprovechan este mes para organizar bodas y otros eventos personales, ya que no están limitados por las restricciones religiosas que se aplican en otros momentos del año. Es un tiempo en el que se celebra la vida y se fortalecen los lazos familiares y comunitarios.",
    parrafo4:
      "En resumen, Jeshván es un mes de transición y calma en el calendario hebreo. Aunque no tiene festividades religiosas específicas, es un tiempo para retomar la rutina diaria después de las festividades de Tishrei y para celebrar la vida y los eventos personales importantes. Es un momento para apreciar la normalidad y fortalecer los vínculos familiares y comunitarios.",
  },
  //Kislev - Sagitario
  {
    id: 3,
    español: "Diciembre",
    hebreo: "כִּסְלֵו",
    nombre: "Kislev",
    descripcion:
      "Este mes incluye la festividad de Janucá, que celebra el milagro del aceite que duró ocho días en el Templo.",
    midot: ["Esperanza", "Fe", "Celebración"],
    festividades: ["Janucá"],
    url: "signos/kislev.PNG",
    parrafo1:
      "Kislev es el noveno mes del calendario hebreo y generalmente cae en noviembre-diciembre. Es un mes lleno de significado y festividades en la tradición judía.",
    parrafo2:
      "Una de las festividades más destacadas en Kislev es Hanukkah, también conocida como la Fiesta de las Luces. Hanukkah se celebra durante ocho días y conmemora la victoria de los macabeos sobre los griegos y la rededicación del Templo en Jerusalén. Durante esta festividad, se encienden las velas de la menorá, se disfrutan comidas especiales y se juega con el dreidel.",
    parrafo3:
      "Kislev también es conocido por ser un mes de esperanza y milagros. En la historia judía, se registran eventos milagrosos que ocurrieron durante Kislev, como la intervención divina en momentos de adversidad.",
    parrafo4:
      "En resumen, Kislev es un mes lleno de festividades y significado en el calendario hebreo. Hanukkah, la Fiesta de las Luces, es una celebración central durante este mes, que trae consigo esperanza y milagros. Es un momento para encender las luces y compartir la alegría con la comunidad, recordando los milagros pasados y renovando la esperanza para el futuro.",
  },
  //Tevet - Capricornio
  {
    id: 4,
    español: "Enero",
    hebreo: "תֵּבֵת",
    nombre: "Tevet",
    descripcion:
      "En este mes se conmemora el ayuno de Asará B'Tevet, que marca el inicio del sitio de Jerusalén por parte de los babilonios.",
    midot: ["Fortaleza", "Reflexión", "Ayuno"],
    festividades: ["Asará B'Tevet"],
    url: "signos/tevet.PNG",

    parrafo1:
      "Tevet es el décimo mes del calendario hebreo y generalmente cae en diciembre-enero. Es un mes que presenta tanto momentos de luto como de esperanza en la tradición judía.",

    parrafo2:
      "Durante Tevet, se conmemora el ayuno de Asará B'Tevet, que marca el sitio y el inicio del asedio a Jerusalén por parte del rey de Babilonia, Nebucadnezzar, un evento que condujo a la destrucción del Templo de Jerusalén. Es un día de luto y reflexión sobre las consecuencias de la guerra y la pérdida del lugar sagrado.",

    parrafo3:
      "A pesar del luto asociado con Asará B'Tevet, Tevet también guarda la promesa de esperanza y redención. En el último día de Tevet, se conmemora la liberación de los judíos que estaban cautivos en Persia, un evento conocido como el Día de la Liberación. Es un momento para recordar la resistencia y la supervivencia frente a la opresión.",

    parrafo4:
      "En resumen, Tevet es un mes de dualidad en el calendario hebreo. Se conmemora la destrucción del Templo de Jerusalén y se reflexiona sobre las consecuencias de la guerra y la pérdida. Sin embargo, también se celebra la liberación y la esperanza de redención. Es un tiempo para recordar la historia, aprender lecciones y mantener viva la esperanza de un futuro mejor.",
  },
  //Shevat - Acuario
  {
    id: 5,
    español: "Febrero",
    hebreo: "שְׁבָט",
    nombre: "Shevat",
    descripcion:
      "En Shevat se celebra el Año Nuevo de los árboles, conocido como Tu B'Shvat, que celebra la renovación de la naturaleza.",
    midot: [
      "Conexión con la naturaleza",
      "Gratitud",
      "Cuidado del medio ambiente",
    ],
    festividades: ["Tu B'Shvat"],
    url: "signos/shevat.PNG",
    parrafo1:
      "Shevat es el undécimo mes del calendario hebreo y generalmente cae en enero-febrero. Es un mes que se asocia con la renovación y la conexión con la naturaleza en la tradición judía.",
    parrafo2:
      "Durante Shevat, se celebra Tu B'Shevat, también conocido como el Año Nuevo de los Árboles. En este día, se honra la importancia de los árboles y las frutas en la vida judía. Es un momento para reflexionar sobre la naturaleza, la ecología y la conexión con la Tierra.",
    parrafo3:
      "Shevat marca el inicio de la temporada de siembra en Israel, cuando los árboles comienzan a florecer y la naturaleza se despierta después del invierno. Es un mes en el que se celebra el renacimiento y se reconoce la importancia de preservar y cuidar el medio ambiente.",
    parrafo4:
      "En resumen, Shevat es un mes que celebra la renovación y la conexión con la naturaleza en el calendario hebreo. A través de Tu B'Shevat, se reflexiona sobre la importancia de los árboles y las frutas, y se destaca la necesidad de cuidar y proteger el medio ambiente. Es un tiempo para apreciar la belleza de la naturaleza y comprometerse con la sostenibilidad y la preservación de nuestro entorno.",
  },
  //Adar - Piscis
  {
    id: 6,
    español: "Marzo",
    hebreo: "אֲדָר",
    nombre: "Adar",
    descripcion:
      "Un mes de alegría y regocijo. Adar contiene la festividad de Purim, que celebra la salvación del pueblo judío en Persia. En los años bisiestos, se agrega un mes adicional de Adar. Es considerado un mes de celebración adicional.",
    midot: ["Alegría", "Valentía", "Generosidad", "Agradecimiento"],
    festividades: ["Purim"],
    url: "signos/adar.PNG",
    parrafo1:
      "Adar es el duodécimo y último mes del calendario hebreo, y generalmente cae en febrero-marzo. Es un mes asociado con la alegría y la celebración en la tradición judía.",
    parrafo2:
      "Durante Adar, se celebra la festividad de Purim, que conmemora la salvación milagrosa de los judíos de una conspiración para destruirlos en la antigua Persia. Purim es una festividad llena de alegría, disfraces, lectura del Meguilá de Ester y el reparto de regalos y comida a los necesitados.",
    parrafo3:
      "Adar es un mes en el que se fomenta la alegría y el gozo, y se destaca la importancia de encontrar la felicidad en la vida cotidiana. Se dice que cuando entra Adar, aumenta la alegría.",
    parrafo4:
      "En los años bisiestos judíos, se agrega un mes adicional llamado Adar Bet (Adar II), que se inserta antes de Adar. Esto se hace para asegurar que el mes de Nisán, que incluye la festividad de Pascua (Pesaj), caiga en primavera según la tradición judía.",
  },
];

export default tablaMeses;

let tablaParashot  = [
    // ...parashot anteriores
    {
      nombre: "Bereshit",
      descripcion:
        "En el principio, Dios creó los cielos y la tierra. Esta parashá narra la historia de la creación del mundo y de la humanidad.",
      midot: ["Creatividad", "Gratitud", "Responsabilidad"],
    },
    {
      nombre: "Noaj",
      descripcion:
        "En esta parashá, Noé construye el arca y sobrevive al diluvio junto con su familia y una pareja de cada especie animal.",
      midot: ["Paciencia", "Integridad", "Perseverancia"],
    },
    {
      nombre: "Lej Lejá",
      descripcion:
        "Dios le ordena a Abraham que salga de su tierra y vaya hacia la tierra que Él le mostrará. Abraham emprende un viaje lleno de pruebas y promesas.",
      midot: ["Fe", "Obediencia", "Confianza"],
    },
    {
      nombre: "Vayerá",
      descripcion:
        "Dios visita a Abraham y le anuncia el nacimiento de su hijo Isaac. También se relata la historia de Sodoma y Gomorra.",
      midot: ["Hospitalidad", "Compasión", "Justicia"],
    },
    {
      nombre: "Jaié Sara",
      descripcion:
        "Después de la muerte de Sara, Abraham busca una esposa para su hijo Isaac. El siervo de Abraham encuentra a Rebeca, quien se convierte en la esposa de Isaac.",
      midot: ["Fidelidad", "Discernimiento", "Búsqueda de socios adecuados"],
    },
    {
      nombre: "Toldot",
      descripcion:
        "Esta parashá narra el conflicto entre los hijos de Isaac, Esaú y Jacob. También se destaca la bendición de Isaac hacia Jacob y la rivalidad entre los hermanos.",
      midot: ["Honestidad", "Perdón", "Resolución de conflictos"],
    },
    {
      nombre: "Vayetze",
      descripcion:
        "Jacob huye de casa y tiene un sueño en el que ve una escalera que llega hasta el cielo. Trabaja para su tío Labán y se casa con sus hijas, Lea y Raquel.",
      midot: ["Humildad", "Paciencia", "Adaptabilidad"],
    },
    {
      nombre: "Vayishlaj",
      descripcion:
        "Jacob se reconcilia con su hermano Esaú después de años de separación. También tiene un encuentro con un ángel y recibe el nombre de Israel.",
      midot: ["Perdón", "Superación del pasado", "Reconciliación"],
    },
    {
      nombre: "Vayeshev",
      descripcion:
        "La historia de José y sus hermanos es el foco principal de esta parashá. José es vendido como esclavo y se convierte en gobernador de Egipto.",
      midot: ["Perseverancia", "Paciencia", "Resiliencia"],
    },
    {
      nombre: "Miketz",
      descripcion:
        "José interpreta los sueños del faraón y es nombrado gobernador de Egipto. Se reencuentra con sus hermanos y les muestra compasión y perdón.",
      midot: ["Compasión", "Perdón", "Humildad"],
    },
    {
      nombre: "Vayigash",
      descripcion:
        "Judá se acerca a José y ofrece ser esclavo en lugar de su hermano Benjamín. José se revela a sus hermanos y los perdona por lo que le hicieron.",
      midot: ["Valentía", "Arrepentimiento", "Generosidad"],
    },
    {
      nombre: "Vayejí",
      descripcion:
        "Jacob bendice a sus hijos antes de su muerte. La parashá concluye con la muerte de Jacob y José en Egipto.",
      midot: ["Bendición", "Reflexión sobre la vida", "Legado"],
    },
    {
      nombre: "Shemot",
      descripcion:
        "La historia del pueblo judío en Egipto comienza con la opresión y esclavitud. Moisés es salvado y comienza su liderazgo para liberar al pueblo.",
      midot: ["Liderazgo", "Empatía", "Valentía"],
    },
    {
      nombre: "Vaerá",
      descripcion:
        "Dios envía las primeras siete plagas sobre Egipto. Moisés y Aarón confrontan al faraón y demandan la liberación del pueblo.",
      midot: ["Persistencia", "Integridad", "Justicia"],
    },
    {
      nombre: "Bo",
      descripcion:
        "Las últimas tres plagas caen sobre Egipto. Los hijos de Israel son instruidos sobre la Pascua y la salida de Egipto.",
      midot: ["Redención", "Cambio", "Celebración"],
    },
    {
      nombre: "Beshalaj",
      descripcion:
        "El pueblo judío cruza el Mar Rojo y canta el Shirat Hayam, el canto de alabanza a Dios. Comienzan a viajar por el desierto.",
      midot: ["Gratitud", "Confianza", "Fortaleza"],
    },
    {
      nombre: "Yitró",
      descripcion:
        "Yitró, suegro de Moisés, llega al campamento y aconseja sobre la estructura del liderazgo. Los Diez Mandamientos son revelados en el Monte Sinaí.",
      midot: ["Sabiduría", "Escucha", "Orden"],
    },
    {
      nombre: "Mishpatim",
      descripcion:
        "Se establecen leyes y reglas para el pueblo judío, abordando diversos aspectos de la vida social y civil.",
      midot: ["Justicia", "Compasión", "Equidad"],
    },
    {
      nombre: "Terumá",
      descripcion:
        "Dios instruye a Moisés sobre la construcción del Mishkán (Tabernáculo), un lugar sagrado de encuentro entre Dios y el pueblo judío.",
      midot: ["Generosidad", "Santidad", "Participación"],
    },
    {
      nombre: "Tetzavé",
      descripcion:
        "Se detallan las instrucciones para la vestimenta de los sacerdotes y los rituales de consagración. También se menciona el aceite especial para la Menorá (candelabro).",
      midot: ["Dedicación", "Honra", "Integridad"],
    },
  
    {
      nombre: "Tetzavé",
      descripcion:
        "Se detallan las instrucciones para la vestimenta de los sacerdotes y los rituales de consagración. También se menciona el aceite especial para la Menorá (candelabro).",
      midot: ["Dedicación", "Honra", "Integridad"],
    },
    {
      nombre: "Ki Tisá",
      descripcion:
        "Moisés recibe instrucciones para realizar el censo del pueblo y se habla del becerro de oro. También se entrega la segunda tabla de los Diez Mandamientos.",
      midot: ["Arrepentimiento", "Responsabilidad", "Fidelidad"],
    },
    {
      nombre: "Vayakel",
      descripcion:
        "El pueblo contribuye con donaciones para la construcción del Mishkán. Se detallan los materiales y las labores necesarias para su construcción.",
      midot: ["Generosidad", "Unión", "Trabajo en equipo"],
    },
    {
      nombre: "Pekudei",
      descripcion:
        "Se realiza el balance y la contabilidad de los materiales utilizados en la construcción del Mishkán. Se verifica que se hayan cumplido todas las especificaciones.",
      midot: ["Responsabilidad", "Orden", "Agradecimiento"],
    },
    {
      nombre: "Vayikrá",
      descripcion:
        "Se describen los diferentes sacrificios y ofrendas que deben ser realizados en el Mishkán. Se establecen las reglas para la relación entre Dios y el pueblo.",
      midot: ["Devoción", "Renovación", "Autocontrol"],
    },
    {
      nombre: "Tsav",
      descripcion:
        "Se brindan instrucciones adicionales sobre los sacrificios y ofrendas. Se destaca la importancia de seguir los mandamientos y rituales de manera adecuada.",
      midot: ["Disciplina", "Consistencia", "Sacrificio"],
    },
    {
      nombre: "Shemini",
      descripcion:
        "Se relata el día en que Aarón y sus hijos fueron consagrados como sacerdotes. También se menciona la muerte de los hijos de Aarón por ofrecer un fuego no autorizado.",
      midot: ["Santidad", "Respeto", "Obediencia"],
    },
    {
      nombre: "Tazría",
      descripcion:
        "Se explican las leyes relacionadas con la pureza e impureza ritual, especialmente en relación con la lepra y las enfermedades de la piel.",
      midot: ["Pureza", "Compassión", "Cuidado"],
    },
    {
      nombre: "Metzorá",
      descripcion:
        "Se continúa con las leyes sobre la pureza e impureza relacionadas con la lepra y las enfermedades de la piel. Se discuten los rituales de purificación.",
      midot: ["Sanación", "Arrepentimiento", "Renovación"],
    },
    {
      nombre: "Ajarei Mot",
      descripcion:
        "Se describen los rituales del Día de la Expiación (Yom Kipur), incluyendo la ofrenda del chivo expiatorio y la purificación del Mishkán.",
      midot: ["Arrepentimiento", "Perdón", "Renovación"],
    },
    {
      nombre: "Ajarei Mot",
      descripcion:
        "Se describen los rituales del Día de la Expiación (Yom Kipur), incluyendo la ofrenda del chivo expiatorio y la purificación del Mishkán.",
      midot: ["Arrepentimiento", "Perdón", "Renovación"],
    },
    {
      nombre: "Kedoshim",
      descripcion:
        "Se presentan las leyes éticas y morales para el pueblo judío. Se enfatiza la importancia de la santidad y el trato justo hacia los demás.",
      midot: ["Santidad", "Bondad", "Justicia"],
    },
    {
      nombre: "Emor",
      descripcion:
        "Se establecen las leyes y los requisitos especiales para los sacerdotes y las festividades del calendario judío, como Pesaj y Shavuot.",
      midot: ["Prestigio", "Dedicación", "Celebración"],
    },
    {
      nombre: "Behar",
      descripcion:
        "Se explican las leyes del año sabático (Shemita) y el año del jubileo. Se habla sobre la justicia social y la devolución de la tierra a sus propietarios originales.",
      midot: ["Equidad", "Generosidad", "Sustentabilidad"],
    },
    {
      nombre: "Bejukotai",
      descripcion:
        "Se describen las bendiciones y maldiciones que seguirán al pueblo judío según su obediencia o desobediencia a los mandamientos de Dios.",
      midot: ["Obediencia", "Responsabilidad", "Consecuencias"],
    },
    {
      nombre: "Bamidbar",
      descripcion:
        "Se realiza un censo de la tribu de Leví y se establece la estructura y los deberes de los levitas. Se describe la disposición de las tribus alrededor del Mishkán.",
      midot: ["Organización", "Colaboración", "Liderazgo"],
    },
    {
      nombre: "Nasó",
      descripcion:
        "Se enumeran las responsabilidades y los deberes de los levitas y se mencionan las leyes de los naziritas. Se presenta la bendición sacerdotal.",
      midot: ["Dedicación", "Separación", "Bendición"],
    },
    {
      nombre: "Behaalotjá",
      descripcion:
        "Se detallan las instrucciones sobre la menorá, las trompetas y el servicio en el Mishkán. También se relata el descontento del pueblo en el desierto.",
      midot: ["Luz", "Escucha", "Confianza"],
    },
    {
      nombre: "Shelaj Lejá",
      descripcion:
        "Moisés envía a doce espías a explorar la Tierra de Israel. La mayoría de ellos trae un informe negativo, lo que lleva al castigo de vagar en el desierto por 40 años.",
      midot: ["Fe", "Confianza", "Valentía"],
    },
    {
      nombre: "Koraj",
      descripcion:
        "Koraj lidera una rebelión contra Moisés y Aarón. El resultado es la intervención divina y la confirmación del liderazgo de Moisés y Aarón.",
      midot: ["Humildad", "Respeto", "Subordinación"],
    },
    {
      nombre: "Jukat",
      descripcion:
        "Se describen las leyes de la Vaca Roja, un rito de purificación para aquellos que han estado en contacto con un cadáver. También se menciona la muerte de Miriam.",
      midot: ["Purificación", "Respeto", "Aceptación de la muerte"],
    },
    {
      nombre: "Balak",
      descripcion:
        "Balak, rey de Moab, intenta maldecir al pueblo judío a través del profeta Balaam. Sin embargo, Balaam es incapaz de maldecir y en su lugar pronuncia bendiciones.",
      midot: ["Protección", "Bendición", "Resistencia al mal"],
    },
    {
      nombre: "Pinjás",
      descripcion:
        "Pinjás defiende el honor de Dios al detener un acto de idolatría y asesinar a un israelita y una mujer madianita. Recibe la promesa de un pacto de paz.",
      midot: ["Zelo", "Fidelidad", "Justicia"],
    },
    {
      nombre: "Matot",
      descripcion:
        "Se tratan las leyes relacionadas con los votos y los juramentos. También se relata la guerra contra los madianitas.",
      midot: ["Compromiso", "Integridad", "Lealtad"],
    },
    {
      nombre: "Masé",
      descripcion:
        "Se enumeran las diversas etapas y ubicaciones de los viajes del pueblo judío por el desierto. Se mencionan las instrucciones para conquistar la Tierra de Israel.",
      midot: ["Perseverancia", "Agradecimiento", "Determinación"],
    },
    {
      nombre: "Devarim",
      descripcion:
        "Moisés comienza su discurso final al pueblo judío, repasando los eventos y las enseñanzas desde la salida de Egipto hasta ese momento.",
      midot: ["Reflexión", "Enseñanza", "Responsabilidad"],
    },
    {
      nombre: "Vaetjanán",
      descripcion:
        "Moisés suplica a Dios que le permita ingresar a la Tierra Prometida. Se repiten los Diez Mandamientos y se enfatiza la importancia de cumplir los mandamientos.",
      midot: ["Humildad", "Súplica", "Compromiso"],
    },
    {
      nombre: "Ekev",
      descripcion:
        "Moisés recuerda la providencia de Dios en el desierto y advierte al pueblo sobre la importancia de cumplir los mandamientos y tener gratitud.",
      midot: ["Agradecimiento", "Fidelidad", "Consecuencias"],
    },
    {
      nombre: "Reé",
      descripcion:
        "Moisés presenta las bendiciones y las maldiciones relacionadas con el cumplimiento de los mandamientos. Se enfatiza la elección del pueblo como pueblo santo.",
      midot: ["Elección", "Discernimiento", "Bendición"],
    },
    {
      nombre: "Shoftim",
      descripcion:
        "Se establecen las leyes y las normas para los jueces y líderes. Se habla de la justicia, la adoración a Dios y la prohibición de la idolatría.",
      midot: ["Justicia", "Integridad", "Lealtad"],
    },
    {
      nombre: "Ki Tetze",
      descripcion:
        "Se enumeran diversas leyes sobre diferentes situaciones, como la guerra, el matrimonio, el trato a los esclavos y los derechos de los hijos ilegítimos.",
      midot: ["Compasión", "Ética", "Justicia"],
    },
    {
      nombre: "Ki Tavó",
      descripcion:
        "Se presentan las leyes y las bendiciones relacionadas con las ofrendas de primicias, el diezmo y otros mandamientos relacionados con la agricultura.",
      midot: ["Gratitud", "Generosidad", "Cumplimiento"],
    },
    {
      nombre: "Nitzavim",
      descripcion:
        "Moisés reúne al pueblo judío y les renueva la alianza con Dios antes de entrar a la Tierra Prometida. También se mencionan las bendiciones y maldiciones por cumplir o no cumplir la Torá.",
      midot: ["Responsabilidad", "Renovación espiritual", "Compromiso"],
    },
    {
      nombre: "Vayelej",
      descripcion:
        "Moisés transmite su liderazgo a Josué y entrega al pueblo la Torá escrita por él. Esta parashá marca el final del libro de Deuteronomio.",
      midot: ["Transición", "Honor", "Mentoría"],
    },
    {
      nombre: "Haazinu",
      descripcion:
        "Moisés recita una canción ante el pueblo judío, profetizando sobre su futuro y recordándoles la importancia de seguir los mandamientos de Dios.",
      midot: ["Escucha", "Humildad", "Lealtad"],
    },
    {
      nombre: "Vezot Haberajá",
      descripcion:
        "Esta parashá es la última del libro de Deuteronomio y de la Torá. Moisés bendice a cada una de las tribus de Israel antes de su fallecimiento.",
      midot: ["Bendición", "Reconocimiento", "Cierre"],
    },
  ];

  export default tablaParashot;
import { Routes, Route } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import Home from '../../pages/Home/Home';
import Festividades from '../../pages/Festividades/Festividades';
import Fiestas from '../../pages/Fiestas/Fiestas';
import Meses from '../../pages/Meses/Meses';
import Mes from '../../pages/Mes/Mes';
import Dias from '../../pages/Dias/Dias';
import Horas from '../../pages/Horas/Horas';
import Midot from '../../pages/Midot/Midot';
import Nosotros from '../../pages/Nosotros/Nosotros';
import Parashot from '../../pages/Parashot/Parashot';
import Contacto from '../../pages/Contacto/Contacto';
import Omer from '../../pages/Omer/Omer';
import Omermodal from '../../pages/Omer/Omermodal';
import Jeshbon from '../../pages/Jeshbon/Jeshbon';
import Tiempo from '../../pages/Tiempo/Tiempo';
import Barra from '../../pages/Barra/Barra';
import Omernro from '../../pages/Omer/Omernro';
import Sefirot from '../../pages/Sefirot/Sefirot';
import Oraciones from '../../pages/Oraciones/Oraciones';
import Rezos from '../../pages/Rezos/Rezos';
import Mitzvot from '../../pages/Mitzvot/Mitzvot';
import Ciclodevida from '../../pages/Ciclodevida/Ciclodevida';
import Prueba from '../../pages/Prueba/Prueba';
import Contactosadm from '../../pages/Admin/Contactosadm/Contactosadm';
import Reservasadm from '../../pages/Admin/Reservasadm/Reservasadm';
import Reservasmod from '../../pages/Admin/Reservasmod/Reservasmod';
import Reservashoy from '../../pages/Admin/Reservashoy/Reservashoy';
import { useState } from 'react';

function Rutas() {
  const [festividad, setFestividad] = useState('');
  const [page, setPage] = useState('1');
  const [mes, setMes] = useState('');
  const [sefira, setSefira] = useState('');

  return (
    <div>
      <Routes>
        <Route path='/' element={<Layout />}> 
            <Route path='/' element={<Home />} />
            <Route path='/barra' element={<Barra />  } /> 
            <Route path='/omernro' element={<Omernro page={page} setPage={setPage}  />} /> 
            <Route path='/omermodal' element={<Omermodal sefira={sefira} setSefira={setSefira}  />} /> 
            <Route path='/omer' element={<Omer page={page} setPage={setPage} />} /> 
            <Route path='/festividades' element={<Festividades festividad={festividad} setFestividad={setFestividad}/>} /> 
            <Route path='/fiestas' element={<Fiestas festividad={festividad}/>} /> 
            <Route path='/meses' element={<Meses mes={mes} setMes={setMes}/>} />  
            <Route path='/mes' element={<Mes mes={mes}/>} /> 
            <Route path='/dias' element={<Dias />} />  
            <Route path='/horas' element={<Horas />} />  
            <Route path='/parashot' element={<Parashot />} />  
            <Route path='/midot' element={<Midot />} />  
            <Route path='/nosotros' element={<Nosotros />} />       
            <Route path='/contacto' element={<Contacto />} /> 
            <Route path='/contactosadm' element={<Contactosadm />} />  
            <Route path='/reservasadm' element={<Reservasadm />} />  
            <Route path='/reservashoy' element={<Reservashoy />} />  
            <Route path='/reservasmod' element={<Reservasmod />} />  
            <Route path='/sefirot' element={<Sefirot />} /> 
            <Route path='/oraciones' element={<Oraciones />} /> 
            <Route path='/rezos' element={<Rezos />} /> 
            <Route path='/mitzvot' element={<Mitzvot />} /> 
            <Route path='/ciclodevida' element={<Ciclodevida />} /> 
            <Route path='/prueba' element={<Prueba />} /> 
            <Route path='/jeshbon' element={<Jeshbon />} /> 
            <Route path='/tiempo' element={<Tiempo />} /> 
        </Route>
      </Routes>
    </div>
  );
}

export default Rutas;
let tablaRezos = [
  {
    id: 1,
    nombre: "Shemá Israel",
    descripcion: "La afirmación fundamental de la unidad de Dios y el amor a Dios",
    significado:
      "El Shemá Israel es una declaración de fe que afirma la creencia en la unicidad de Dios y el amor a Dios con todo el corazón, el alma y la fuerza. Se recita dos veces al día, por la mañana y por la noche, y es considerada una de las oraciones más importantes en el judaísmo.",
    momento: "Se recita en los servicios matutinos y vespertinos, así como antes de dormir.",
  },
  {
    id: 2,
    nombre: "Amidá",
    descripcion: "La oración central y más importante en el servicio diario",
    significado:
      "El Amidá, también conocido como la oración de la 'Shmoneh Esreh', es una oración que abarca peticiones y alabanzas a Dios. Es una parte central del servicio diario y se recita en silencio mientras se está de pie. El Amidá permite una conexión directa y personal con Dios, donde se pueden expresar gratitud, pedir bendiciones y buscar perdón.",
    momento: "Se recita en cada servicio diario: por la mañana, tarde y noche.",
  },
  {
    id: 3,
    nombre: "Kidush",
    descripcion: "Bendición recitada en Shabat y festividades para santificar el día",
    significado:
      "El Kidush es una bendición especial recitada en Shabat y festividades judías para santificar el día y declararlo como un tiempo sagrado. Al recitar el Kidush, se reconoce la santidad del Shabat o la festividad y se agradece a Dios por el descanso y la bendición que se experimenta en ese momento.",
    momento: "Se recita al comienzo de la comida festiva de Shabat o de las festividades.",
  },
  {
    id: 4,
    nombre: "Havdalá",
    descripcion: "Ceremonia que marca el final de Shabat y la transición a la semana",
    significado:
      "La Havdalá es una ceremonia que marca el final del Shabat y la transición de un tiempo sagrado a uno secular. Se realizan bendiciones sobre el vino, las especias y la luz de una vela entrelazados con oraciones específicas. La Havdalá simboliza el reconocimiento de la singularidad del Shabat y el comienzo de una nueva semana llena de bendiciones.",
    momento: "Se recita al finalizar el Shabat, después de la salida de las estrellas.",
  },
  {
    id: 5,
    nombre: "Birkat Hamazón",
    descripcion: "Bendición de agradecimiento después de las comidas",
    significado:
      "Birkat Hamazón, también conocido como la bendición después de las comidas o el Bentching, es una expresión de gratitud a Dios por los alimentos que hemos consumido. Es una forma de reconocer que todas nuestras necesidades son proporcionadas por Dios y de mostrar agradecimiento por su generosidad.",
    momento: "Se recita después de las comidas principales, como el almuerzo y la cena.",
  },
  {
    id: 6,
    nombre: "Kadish",
    descripcion: "Oración de alabanza y consuelo recitada en diversos momentos",
    significado:
      "El Kadish es una oración de alabanza y consuelo que se recita en varios momentos durante los servicios religiosos y en momentos de duelo. Existen diferentes versiones de Kadish que se utilizan para marcar transiciones en el servicio y para honrar y recordar a los seres queridos fallecidos. También se considera una expresión de esperanza y consuelo en momentos de pérdida.",
    momento:
      "Se recita en diversas ocasiones, como durante los servicios de Shabat, festividades y en momentos de duelo.",
  },
];

export default tablaRezos;
import * as React from 'react';
import Box from '@mui/material/Box';
import tablaSefirot from "../Tablas/Tablasefirot";

const  Omermodal = ({ sefira, setSefira })=> 
{
  return (
    <Box sx={{ width: '50%' }}>
      {tablaSefirot
          .filter((c1) => c1.nombre.toString() === sefira.toString())
          .map((p1, idx1) => (
          <p>{p1.descripcion} </p>
       ))}
    </Box>
  );
}

export default  Omermodal;
import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import tablaFestividades from "../Tablas/Tablafestividades";
const Festividades = ({ festividad, setFestividad }) => {


  const navigate = useNavigate();
  const handleFestividad = (p) => {
    setFestividad(p);
    navigate('/Fiestas');
  };

  return (
    <section Festividades>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h3> Festividades del Año</h3>
      </div>
      <Container>
       <p>Cada festividad judía tiene una energía única que puede ser aprovechada para trabajar en el desarrollo de los midot, los rasgos de carácter y cualidades éticas. Aquí te presento algunas ideas sobre cómo puedes utilizar la energía de cada festividad para cultivar los midot</p>
       <p>En resumen, cada festividad del calendario judío tiene su propio significado y propósito. Desde la introspección y el arrepentimiento en Rosh Hashaná y Yom Kipur, hasta la alegría y la gratitud en Sucot y Janucá, y la celebración de la libertad en Pesaj, estas festividades nos brindan oportunidades para conectarnos con nuestra historia, valores y espiritualidad judía, y nos invitan a reflexionar, celebrar y crecer en nuestra fe.</p>
        <Row xs={1} md={2} lg={3} className="g-10 text-center">
          {tablaFestividades.map((p, idx) => (
            <Col p={p} key={idx}>
              <Card style={{ width: "20rem", height: "34rem" }} className="m-3">
                <Card.Body>
                  <Card.Title>{p.festividad}</Card.Title>               
                  <Card.Subtitle mb="2" text="muted">
                    {p.resumen}
                  </Card.Subtitle>
                  <Card.Img variant="top" mb="2" src={p.url} height={"200"} />
                  <Card.Text>{p.descripcion}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      variant="text"
                      size="small"
                      fullWidth={true}
                      className="mt-3"
                      color="primary"
                      onClick={() => handleFestividad(p.festividad)} > Leer más </Button>
                  </Card.Footer>
              
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

    </section>
  );
};

export default Festividades;

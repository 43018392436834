const tablaTiempo = [
    {
        id: 1,
        nombre: "El Ciclo de la Vida",
        descripcion:
            "El ciclo de vida es un proceso continuo que nos lleva desde el nacimiento hasta la muerte. Cada etapa de la vida tiene sus propias características, desafíos y oportunidades de crecimiento. Desde la infancia hasta la adultez y la vejez, cada fase nos invita a desarrollar nuestras capacidades, cultivar virtudes y encontrar un mayor propósito en nuestro viaje.",
        url: "/ciclodevida",
        imagen: "/fondos/ciclovida.PNG",
    },
    {
        id: 2,
        nombre: "El Año y sus Festividades",
        descripcion: "El año, en particular en el calendario hebreo, está marcado por festividades y eventos significativos que nos conectan con nuestra historia y tradiciones. Estas festividades, como Rosh Hashaná, Yom Kipur, Pésaj y Sucot, nos brindan la oportunidad de reflexionar, celebrar y renovar nuestra conexión con lo divino y con nuestra comunidad.",
        url: "/festividades",
        imagen: "/fondos/elano.PNG",
    },
    {
        id: 3,
        nombre: "Los Meses del Año",
        descripcion: "Los meses, las unidades más pequeñas en el calendario, también tienen sus propias energías y propósitos. Cada mes nos invita a explorar diferentes aspectos de nuestra vida y del mundo que nos rodea. Desde la alegría y la renovación de Nisán hasta la introspección y la redención de Elul, cada mes tiene su propia energía que nos guía en nuestro crecimiento espiritual.",
        url: "/meses",
        imagen: "/fondos/mesesdelano.PNG",
    },
    {
        id: 4,
        nombre: "Las Semanas y las Parashot",
        descripcion: "Las semanas y las parashot, en la tradición judía, nos brindan un enfoque semanal en el estudio y la reflexión de la Torá. Cada semana se nos presenta una nueva porción de la Torá para explorar y extraer lecciones relevantes para nuestras vidas. Estas parashot nos desafían a examinar nuestras acciones, cultivar virtudes y aprender de las enseñanzas antiguas que siguen siendo relevantes en la actualidad.",
        url: "/parashot",
        imagen: "/fondos/lasemana.PNG",
    },
    {
        id: 5,
        nombre: "Los Días de la Semana",
        descripcion: "Los días son unidades  más pequeñas del tiempo que nos recuerdan la importancia de cada momento. A lo largo del día, tenemos la oportunidad de ser conscientes de nuestras acciones, cultivar nuestras virtudes y aprovechar al máximo cada instante. Cada hora nos ofrece una nueva oportunidad de enfoque, productividad y conexión con lo divino.",
        url: "/dias",
        imagen: "/fondos/eldia.PNG",
    },
    {
        id: 6,
        nombre: "Las Horas del Día",
        descripcion: "Las horas, por último, son unidades aún más pequeñas del tiempo que nos recuerdan la importancia de cada momento. A lo largo del día, tenemos la oportunidad de ser conscientes de nuestras acciones, cultivar nuestras virtudes y aprovechar al máximo cada instante. Cada hora nos ofrece una nueva oportunidad de enfoque, productividad y conexión con lo divino.",
        url: "/horas",
        imagen: "/fondos/lashoras.PNG",
    },
];

export default tablaTiempo;
let tablaOmer = [
  {
    id: 1,
    semana: 1,
    nombre: "Día 1",
    sefira2: ["Jesed"],
    sefira1: ["Jesed"],
    energia: "Amor y bondad desinteresada",
    recomendaciones: "Practicar actos de amor y bondad hacia los demás sin esperar nada a cambio.",
  },
  {
    id: 2,
    semana: 1,
    nombre: "Día 2",
    sefira2: ["Guevura"],
    sefira1: ["Jesed"],
    energia: "Disciplina y límites amorosos",
    recomendaciones: "Establecer límites y disciplina con amor y compasión.",
  },
  {
    id: 3,
    semana: 1,
    nombre: "Día 3",
    sefira2: ["Tiferet"],
    sefira1: ["Jesed"],
    energia: "Armonía y compasión",
    recomendaciones: "Cultivar la armonía en las relaciones y practicar la compasión.",
  },
  {
    id: 4,
    semana: 1,
    nombre: "Día 4",
    sefira2: ["Netzaj"],
    sefira1: ["Jesed"],
    energia: "Persistencia y altruismo",
    recomendaciones: "Persistir en los actos altruistas y desinteresados.",
  },
  {
    id: 5,
    semana: 1,
    nombre: "Día 5",
    sefira2: ["Hod"],
    sefira1: ["Jesed"],
    energia: "Gratitud y humildad amorosa",
    recomendaciones: "Expresar gratitud y practicar la humildad con amor.",
  },
  {
    id: 6,
    semana: 1,
    nombre: "Día 6",
    sefira2: ["Yesod"],
    sefira1: ["Jesed"],
    energia: "Fundación y lealtad amorosa",
    recomendaciones: "Fortalecer los cimientos de las relaciones y practicar la lealtad amorosa.",
  },
  {
    id: 7,
    semana: 1,
    nombre: "Día 7",
    sefira2: ["Maljut"],
    sefira1: ["Jesed"],
    energia: "Receptividad y generosidad amorosa",
    recomendaciones: "Abrirse a recibir y practicar la generosidad amorosa hacia los demás.",
  },
  {
    id: 8,
    semana: 2,
    nombre: "Día 8",
    sefira2: ["Jesed"],
    sefira1: ["Guevura"],
    energia: "Bondad equilibrada y controlada",
    recomendaciones: "Equilibrar la bondad con límites y control.",
  },
  {
    id: 9,
    semana: 2,
    nombre: "Día 9",
    sefira2: ["Guevura"],
    sefira1: ["Guevura"],
    energia: "Disciplina y rigor",
    recomendaciones: "Aplicar disciplina y rigor en el autocontrol y en las acciones.",
  },
  {
    id: 10,
    semana: 2,
    nombre: "Día 10",
    sefira2: ["Tiferet"],
    sefira1: ["Guevura"],
    energia: "Armonía en la disciplina",
    recomendaciones: "Buscar la armonía y el equilibrio en el cumplimiento de la disciplina.",
  },
  {
    id: 11,
    semana: 2,
    nombre: "Día 11",
    sefira2: ["Netzaj"],
    sefira1: ["Guevura"],
    energia: "Persistencia en la disciplina",
    recomendaciones: "Persistir en la disciplina y el autocontrol con determinación.",
  },
  {
    id: 12,
    semana: 2,
    nombre: "Día 12",
    sefira2: ["Hod"],
    sefira1: ["Guevura"],
    energia: "Gratitud en la disciplina",
    recomendaciones: "Agradecer por la disciplina y cultivar la humildad en su práctica.",
  },
  {
    id: 13,
    semana: 2,
    nombre: "Día 13",
    sefira2: ["Yesod"],
    sefira1: ["Guevura"],
    energia: "Fundación en la disciplina",
    recomendaciones: "Fortalecer la base de la disciplina y mantener la integridad.",
  },
  {
    id: 14,
    semana: 2,
    nombre: "Día 14",
    sefira2: ["Maljut"],
    sefira1: ["Guevura"],
    energia: "Receptividad en la disciplina",
    recomendaciones: "Abrirse a recibir la disciplina y cultivar una actitud receptiva.",
  },
  {
    id: 15,
    semana: 3,
    nombre: "Día 15",
    sefira2: ["Jesed"],
    sefira1: ["Tiferet"],
    energia: "Amor en la armonía",
    recomendaciones: "Manifestar amor en la armonía de las relaciones y acciones.",
  },
  {
    id: 16,
    semana: 3,
    nombre: "Día 16",
    sefira2: ["Guevura"],
    sefira1: ["Tiferet"],
    energia: "Disciplina en la armonía",
    recomendaciones: "Aplicar la disciplina en busca de la armonía y el equilibrio.",
  },
  {
    id: 17,
    semana: 3,
    nombre: "Día 17",
    sefira2: ["Tiferet"],
    sefira1: ["Tiferet"],
    energia: "Armonía en la armonía",
    recomendaciones: "Cultivar la armonía interna y buscar el equilibrio en todas las áreas.",
  },
  {
    id: 18,
    semana: 3,
    nombre: "Día 18",
    sefira2: ["Netzaj"],
    sefira1: ["Tiferet"],
    energia: "Persistencia en la armonía",
    recomendaciones: "Persistir en la búsqueda de la armonía y mantener la determinación.",
  },
  {
    id: 19,
    semana: 3,
    nombre: "Día 19",
    sefira2: ["Hod"],
    sefira1: ["Tiferet"],
    energia: "Gratitud en la armonía",
    recomendaciones: "Expresar gratitud por la armonía y valorar los aspectos positivos.",
  },
  {
    id: 20,
    semana: 3,
    nombre: "Día 20",
    sefira2: ["Yesod"],
    sefira1: ["Tiferet"],
    energia: "Fundación en la armonía",
    recomendaciones: "Fortalecer la base de la armonía y mantener la integridad.",
  },
  {
    id: 21,
    semana: 3,
    nombre: "Día 21",
    sefira2: ["Maljut"],
    sefira1: ["Tiferet"],
    energia: "Receptividad en la armonía",
    recomendaciones: "Abrirse a recibir y ser receptivos en la búsqueda de la armonía.",
  },
  {
    id: 22,
    semana: 4,
    nombre: "Día 22",
    sefira2: ["Jesed"],
    sefira1: ["Netzaj"],
    energia: "Amor en la persistencia",
    recomendaciones: "Manifestar amor en la persistencia y la constancia de las acciones.",
  },
  {
    id: 23,
    semana: 4,
    nombre: "Día 23",
    sefira2: ["Guevura"],
    sefira1: ["Netzaj"],
    energia: "Disciplina en la persistencia",
    recomendaciones: "Aplicar la disciplina en la perseverancia y el logro de metas.",
  },
  {
    id: 24,
    semana: 4,
    nombre: "Día 24",
    sefira2: ["Tiferet"],
    sefira1: ["Netzaj"],
    energia: "Armonía en la persistencia",
    recomendaciones: "Cultivar la armonía en la perseverancia y mantener el equilibrio.",
  },
  {
    id: 25,
    semana: 4,
    nombre: "Día 25",
    sefira2: ["Netzaj"],
    sefira1: ["Netzaj"],
    energia: "Persistencia en la persistencia",
    recomendaciones: "Persistir con determinación en la búsqueda de los objetivos.",
  },
  {
    id: 26,
    semana: 4,
    nombre: "Día 26",
    sefira2: ["Hod"],
    sefira1: ["Netzaj"],
    energia: "Gratitud en la persistencia",
    recomendaciones: "Expresar gratitud por la perseverancia y valorar los logros.",
  },
  {
    id: 27,
    semana: 4,
    nombre: "Día 27",
    sefira2: ["Yesod"],
    sefira1: ["Netzaj"],
    energia: "Fundación en la persistencia",
    recomendaciones: "Fortalecer la base de la perseverancia y mantener la integridad.",
  },
  {
    id: 28,
    semana: 4,
    nombre: "Día 28",
    sefira2: ["Maljut"],
    sefira1: ["Netzaj"],
    energia: "Receptividad en la persistencia",
    recomendaciones: "Abrirse a recibir y ser receptivos en el camino de la perseverancia.",
  },
  {
    id: 29,
    semana: 5,
    nombre: "Día 29",
    sefira2: ["Jesed"],
    sefira1: ["Hod"],
    energia: "Amor en la humildad",
    recomendaciones: "Manifestar amor en la práctica de la humildad y el respeto.",
  },
  {
    id: 30,
    semana: 5,
    nombre: "Día 30",
    sefira2: ["Guevura"],
    sefira1: ["Hod"],
    energia: "Disciplina en la humildad",
    recomendaciones: "Aplicar la disciplina en el desarrollo de la humildad y la modestia.",
  },
  {
    id: 31,
    semana: 5,
    nombre: "Día 31",
    sefira2: ["Tiferet"],
    sefira1: ["Hod"],
    energia: "Armonía en la humildad",
    recomendaciones: "Cultivar la armonía en la práctica de la humildad y la apreciación.",
  },
  {
    id: 32,
    semana: 5,
    nombre: "Día 32",
    sefira2: ["Netzaj"],
    sefira1: ["Hod"],
    energia: "Persistencia en la humildad",
    recomendaciones: "Persistir en la práctica de la humildad y mantener la determinación.",
  },
  {
    id: 33,
    semana: 5,
    nombre: "Día 33",
    sefira2: ["Hod"],
    sefira1: ["Hod"],
    energia: "Gratitud en la humildad",
    recomendaciones: "Expresar gratitud por la humildad y valorar las lecciones aprendidas.",
  },
  {
    id: 34,
    semana: 5,
    nombre: "Día 34",
    sefira2: ["Yesod"],
    sefira1: ["Hod"],
    energia: "Fundación en la humildad",
    recomendaciones: "Fortalecer la base de la humildad y mantener la integridad.",
  },
  {
    id: 35,
    semana: 5,
    nombre: "Día 35",
    sefira2: ["Maljut"],
    sefira1: ["Hod"],
    energia: "Receptividad en la humildad",
    recomendaciones: "Abrirse a recibir y ser receptivos en la práctica de la humildad.",
  },
  {
    id: 36,
    semana: 6,
    nombre: "Día 36",
    sefira2: ["Jesed "],
    sefira1: ["Yesod"],
    energia: "Amor en la fundamentación",
    recomendaciones: "Manifestar amor en el establecimiento de bases sólidas y confiables.",
  },
  {
    id: 37,
    semana: 6,
    nombre: "Día 37",
    sefira2: ["Guevura"],
    sefira1: ["Yesod"],
    energia: "Disciplina en la fundamentación",
    recomendaciones: "Aplicar la disciplina en la construcción de una base sólida y estable.",
  },
  {
    id: 38,
    semana: 6,
    nombre: "Día 38",
    sefira2: ["Tiferet "],
    sefira1: ["Yesod"],
    energia: "Armonía en la fundamentación",
    recomendaciones: "Cultivar la armonía en la construcción de bases sólidas y equilibradas.",
  },
  {
    id: 39,
    semana: 6,
    nombre: "Día 39",
    sefira2: ["Netzaj "],
    sefira1: ["Yesod"],
    energia: "Persistencia en la fundamentación",
    recomendaciones: "Persistir en la construcción de una base sólida y mantener la determinación.",
  },
  {
    id: 40,
    semana: 6,
    nombre: "Día 40",
    sefira2: ["Hod "],
    sefira1: ["Yesod"],
    energia: "Gratitud en la fundamentación",
    recomendaciones: "Expresar gratitud por la base sólida construida y valorar su importancia.",
  },
  {
    id: 41,
    semana: 6,
    nombre: "Día 41",
    sefira2: ["Yesod "],
    sefira1: ["Yesod"],
    energia: "Fundación en la fundamentación",
    recomendaciones: "Fortalecer la base establecida y mantener la integridad en la construcción.",
  },
  {
    id: 42,
    semana: 6,
    nombre: "Día 42",
    sefira2: ["Maljut "],
    sefira1: ["Yesod"],
    energia: "Receptividad en la fundamentación",
    recomendaciones: "Abrirse a recibir y ser receptivos en la construcción de una base sólida.",
  },
  {
    id: 43,
    semana: 7,
    nombre: "Día 43",
    sefira2: ["Jesed "],
    sefira1: ["Maljut"],
    energia: "Amor en la receptividad",
    recomendaciones:
      "Manifestar amor en la apertura a recibir y en la receptividad hacia los demás.",
  },
  {
    id: 44,
    semana: 7,
    nombre: "Día 44",
    sefira2: ["Guevura"],
    sefira1: ["Maljut"],
    energia: "Disciplina en la receptividad",
    recomendaciones:
      "Aplicar la disciplina en la apertura a recibir y en la receptividad con límites.",
  },
  {
    id: 45,
    semana: 7,
    nombre: "Día 45",
    sefira2: ["Tiferet "],
    sefira1: ["Maljut"],
    energia: "Armonía en la receptividad",
    recomendaciones:
      "Cultivar la armonía en la apertura a recibir y en la receptividad equilibrada.",
  },
  {
    id: 46,
    semana: 7,
    nombre: "Día 46",
    sefira2: ["Netzaj "],
    sefira1: ["Maljut"],
    energia: "Persistencia en la receptividad",
    recomendaciones: "Persistir en la apertura a recibir y en la receptividad constante.",
  },
  {
    id: 47,
    semana: 7,
    nombre: "Día 47",
    sefira2: ["Hod "],
    sefira1: ["Maljut"],
    energia: "Gratitud en la receptividad",
    recomendaciones:
      "Expresar gratitud por la apertura a recibir y valorar las bendiciones recibidas.",
  },
  {
    id: 48,
    semana: 7,
    nombre: "Día 48",
    sefira2: ["Yesod "],
    sefira1: ["Maljut"],
    energia: "Fundación en la receptividad",
    recomendaciones: "Fortalecer la base de la receptividad y mantener la integridad en recibirla.",
  },
  {
    id: 49,
    semana: 7,
    nombre: "Día 49",
    sefira2: ["Maljut "],
    sefira1: ["Maljut"],
    energia: "Receptividad en la receptividad",
    recomendaciones: "Abrirse plenamente a recibir y ser receptivos en todo momento.",
  },
];

export default tablaOmer;
import React from "react";
import { List } from "bootstrap-4-react";
import Container from "react-bootstrap/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import tablaFestividades from "../Tablas/Tablafestividades";

function Fiestas({ festividad }) {
  return (
    <section Fiestas>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h1> {festividad}</h1>
      </div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          {tablaFestividades
            .filter((c) => c.festividad === festividad.toString())
            .map((p, idx) => (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h2>{p.frase}</h2>
                  <img
                    className="d-block w-100 image-responsive justify-content-center ps- mb-5 mt-3"
                    alt={p.descripcion}
                    src={p.url}
                  />
                  <h6>Energía de la festividad</h6>
                  <p>{p.energia}</p>
                  <h6>Midot para trabajar</h6>
                  <List>
                    {p.midot.map((q, idx1) => (
                      <List.Item> {q}</List.Item>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <p>{p.parrafo1}</p>
                  <p>{p.parrafo2}</p>
                  <p>{p.parrafo3}</p>
                  <p>{p.parrafo4}</p>
                  <Box
                    component="form"
                    sx={{ "& > :not(style)": { m: 1, width: "36ch", mt: 1 } }}
                  >
                    <Button
                      variant="text"
                      size="small"
                      className="mt-3"
                      color="success"
                      href="/festividades"
                    >
                      Volver
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ))}
        </Box>
      </Container>
    </section>
  );
}

export default Fiestas;

import * as React from 'react';
import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import tablaTiempo from "../Tablas/Tablatiempo";
import Button from "@mui/material/Button";

const Tiempo = () => {
    const navigate = useNavigate();
    return (
        <section nosotros className="mb-5">
            <div className="bg-dark text-bg-dark pb-2 mb-5 ps-5  mb-1 text-center">
                <h3> El Tiempo</h3>
            </div>
            <Container>
            <p> Los ciclos del tiempo son una parte fundamental de nuestra existencia y nos brindan estructura y significado en nuestra vida diaria. A lo largo de nuestro recorrido, experimentamos diferentes niveles de ciclos que abarcan desde nuestra propia vida hasta los períodos más amplios del año, los meses, las semanas, las parashot, los días y las horas.</p>
            <Row xs={1} md={2} lg={3} className="g-4 text-center">
                {tablaTiempo.map((p, idx) => (
                    <Col p={p} key={idx}>
                        <Card style={{ width: "30 rem", height: "50 rem" }} className="m-3 p-3">
                            <Card.Title>{p.nombre} </Card.Title>
                            <Card.Body>
                                <Card.Img variant="top" mb="2" image-responsive src={p.imagen} height={"200"} />
                                <Card.Text>{p.descripcion}</Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Button
                                    variant="text"
                                    size="small"
                                    fullWidth={true}
                                    className="mt-3"
                                    color="primary"
                                    onClick={() => navigate(p.url)} > Leer más </Button>
                            </Card.Footer>

                        </Card>
                    </Col>
                ))}
            </Row>
            <p>En resumen, los ciclos del tiempo, desde la vida hasta los años, meses, semanas, días y horas, nos brindan estructura y significado. Nos invitan a reflexionar, crecer y conectarnos con nuestra espiritualidad en cada nivel. A través de estos ciclos, podemos aprovechar al máximo nuestra existencia y cultivar una vida llena de propósito y significado.</p>
            </Container>
        </section>
    );
}
export default Tiempo
import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

import tablaOraciones from "../Tablas/Tablaoraciones";
const Oraciones = () => {

   return (
    <section Oraciones>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h3> Oraciones diarias</h3>
      </div>
      <Container>
        <Row xs={1} md={2} lg={3} className="g-10 text-center">
          {tablaOraciones.map((p, idx) => (
            <Col p={p} key={idx}>
              <Card style={{ width: "22rem", height: "30rem" }} className="m-3">
                <Card.Body>
                  <Card.Title>{p.nombre}</Card.Title>  
                  <Card.Text>{p.descripcion}</Card.Text>             
                  <Card.Img variant="top" mb="2" src={p.url} height={"120"} />
                  <Card.Text>{p.proposito}</Card.Text>
                  <Card.Text>{p.horario}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};


 
export default Oraciones;

 
let tablaDias = [
    {
      id: 1,
      nombre: "Domingo",
      descripcion:
        "El primer día de la semana. Es un día para descansar, reflexionar y pasar tiempo de calidad con la familia.",
      mida: ["Descanso", "Reflexión", "Familia"], hebreo: "יוֹם רִאשׁוֹן" ,
    },
    {
      id: 2,
      nombre: "Lunes",
      descripcion:
        "El segundo día de la semana. Es un día para comenzar con energía y establecer metas para la semana.",
      mida: ["Energía", "Metas", "Productividad"], hebreo: "יוֹם שֵׁנִי" ,
    },
    {
      id: 3,
      nombre: "Martes",
      descripcion:
        "El tercer día de la semana. Es un día para llevar a cabo tareas y responsabilidades con diligencia y eficiencia.",
      mida: ["Diligencia", "Eficiencia", "Responsabilidad"], hebreo: "יוֹם שְׁלִישִׁי",
    },
    {
      id: 4,
      nombre: "Miércoles",
      descripcion:
        "El cuarto día de la semana. Es un día para buscar el equilibrio entre el trabajo y el descanso, y tomar tiempo para cuidar de uno mismo.",
      mida: ["Equilibrio", "Autocuidado", "Organización"], hebreo: "יוֹם רְבִיעִי",
    },
    {
      id: 5,
      nombre: "Jueves",
      descripcion:
        "El quinto día de la semana. Es un día para tomar decisiones importantes y avanzar hacia tus objetivos con determinación.",
      mida: ["Determinación", "Toma de decisiones", "Progreso"], hebreo: "יוֹם חֲמִישִׁי",
    },
    {
      id: 6,
      nombre: "Viernes",
      descripcion:
        "El sexto día de la semana. Es un día de preparación para el Shabbat, de reflexión espiritual y de conexión con la familia y la comunidad.",
      mida: ["Preparación", "Reflexión espiritual", "Conexión"],hebreo:  "יוֹם שִׁשִּׁי",
    },
    {
      id: 7,
      nombre: "Sábado",
      descripcion:
        "El séptimo día de la semana. Es un día para desconectar del trabajo, dedicarse al estudio y la oración, y disfrutar de la compañía de los seres queridos.",
      mida: ["Descanso", "Estudio", "Oración", "Familia"], hebreo: "שַׁבָּת",
    },
  ];
  
  export default tablaDias;
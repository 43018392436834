import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import tablaOmer from "../Tablas/Tablaomer";
import tablaSefirot from "../Tablas/Tablasefirot";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Stack from '@mui/material/Stack';
 
 
const Omernro = ({ page, setPage }) => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const navigate = useNavigate();
  const handlePagePrev = (p) => {
    if (page > 1) {
      setPage(page - 1);
      navigate("/Omernro");
    } else navigate("/Omer");

  };
  const handlePageNext = (p) => {
    if (page < 7) {
      setPage(page + 1);
      navigate("/Omernro");
    } else navigate("/Omer");

  };

 


  return (
    <section OmerNro>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <Container>
          <Row xs={3} md={3} lg={3} className="g-10">
            <Col>
              <Button
                variant="text"
                fullWidth={true}
                color="primary"
                onClick={() => handlePagePrev()}
              >
                {" "}
                Anterior{" "}
              </Button>
            </Col>
            <Col>
              <h3>SEMANA {page}</h3>

            </Col>
            <Col>
              <Button
                variant="text"
                fullWidth={true}
                color="primary"
                onClick={() => handlePageNext()}
              >
                {" "}
                Siguiente{" "}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row xs={1} md={2} lg={3} className="g-10 text-center">


          {tablaOmer
            .filter((c) => c.semana === page)
            .map((p, idx) => (
              <Col p={p} key={idx}>
                <Card style={{ width: "22rem", height: "25rem" }} className="m-3">
                  <Card.Body>
                    <Card.Title>{p.nombre}</Card.Title>
                    <Stack>
                    <Button variant="primary" onClick={() => setShow2(true)}>
                      {p.sefira2}
                    </Button>

                    <Card.Text>DE</Card.Text>
                    <Button variant="primary" onClick={() => setShow1(true)}>
                      {p.sefira1}
                    </Button>
                    <p> busqueda {p.sefira2}  </p>
                   </Stack>
                    <Modal
                      show={show1}
                      onHide={() => setShow1(false)}
                      dialogClassName="modal-90w"
                      centered
                      aria-labelledby="id1"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="id1">
                          {p.sefira1}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      {tablaSefirot
                      .filter((c1) => c1.nombre.toString() === p.sefira1.toString())
                      .map((p1, idx1) => (
                        <p>{p1.descripcion} </p>
                      ))}
                      </Modal.Body>
                    </Modal>
                    <Modal
                      show={show2}
                      onHide={() => setShow2(false)}
                      dialogClassName="modal-90w"
                      centered
                      aria-labelledby="id2"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="id2">
                          {p.sefira2}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      {tablaSefirot
                      .filter((c2) => c2.nombre.toString() === p.sefira2.toString())
                      .map((p2, idx2) => (
                        <p>{p2.descripcion} </p>
                      ))}
                      </Modal.Body>
                    </Modal>
                  </Card.Body>
                  <Card.Body>
                    <Card.Text>{p.energia}</Card.Text>
                    <Card.Img variant="top" mb="2" src={p.url} height={"120"} />
                    <Card.Text>{p.sefira2} de {p.sefira1}</Card.Text>
                    <Card.Text>{p.recomendaciones}</Card.Text>
                  </Card.Body>

                </Card>
              </Col>
            ))}
        </Row>
      </Container>

    </section >
  );
};


export default Omernro;
import { Card, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

import tablaCicloDeVida from "../Tablas/Tablaciclodevida";
const Ciclodevida = () => {

   return (
    <section Ciclodevida>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h3>El Ciclo de la Vida</h3>
      </div>
      <Container>
      <p> El ciclo de vida es un proceso continuo que nos lleva desde el nacimiento hasta la muerte. Cada etapa de la vida tiene sus propias características, desafíos y oportunidades de crecimiento. Desde la infancia hasta la adultez y la vejez, cada fase nos invita a desarrollar nuestras capacidades, cultivar virtudes y encontrar un mayor propósito en nuestro viaje.</p>
        <Row xs={1} md={2} lg={3} className="g-10 text-center">
          {tablaCicloDeVida.map((p, idx) => (
            <Col p={p} key={idx}>
              <Card style={{ width: "26rem", height: "36rem" }}>
                <Card.Body>
                  <Card.Title>{p.nombre}</Card.Title>  
                  <Card.Img variant="top" p="5" image-responsive src={p.imagen} width={"50"} height={"180"} />
                  <Card.Text>{p.descripcion}</Card.Text>             
                  <Card.Text>{p.significado}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};


 
export default Ciclodevida;
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function Prueba() {
  return (
    <ButtonGroup vertical>
      <Button>Botánicas y Junglas</Button>
      <Button></Button>

      <DropdownButton
        as={ButtonGroup}
        title="Revestimientos"
        id="bg-vertical-dropdown-1"
      >
        <Dropdown.Item eventKey="1">Telas</Dropdown.Item>
        <Dropdown.Item eventKey="2">Maderas</Dropdown.Item>
        <Dropdown.Item eventKey="2">Concretos</Dropdown.Item>
        <Dropdown.Item eventKey="2">Ladrillos</Dropdown.Item>
      </DropdownButton>

      <Button>Button</Button>
      <Button>Button</Button>

      <DropdownButton
        as={ButtonGroup}
        title="Mapas"
        id="bg-vertical-dropdown-2"
      >
        <Dropdown.Item eventKey="1">Adulto</Dropdown.Item>
        <Dropdown.Item eventKey="2">Infantiles</Dropdown.Item>
      </DropdownButton>
      <Button>Adultos</Button>
      <DropdownButton
        as={ButtonGroup}
        title="Juveniles"
        id="bg-vertical-dropdown-3"
      >
        <Dropdown.Item eventKey="1">Unisex></Dropdown.Item>
        <Dropdown.Item eventKey="1">Hombre></Dropdown.Item>
        <Dropdown.Item eventKey="2">Mujer</Dropdown.Item>
      </DropdownButton>
      <DropdownButton
        as={ButtonGroup}
        title="Infantiles"
        id="bg-vertical-dropdown-3"
      >
        <Dropdown.Item eventKey="1">Unisex</Dropdown.Item>
        <Dropdown.Item eventKey="1">Niños</Dropdown.Item>
        <Dropdown.Item eventKey="2">Niñas</Dropdown.Item>
      </DropdownButton>
      <DropdownButton
        as={ButtonGroup}
        title="Bebes"
        id="bg-vertical-dropdown-3"
      >
        <Dropdown.Item eventKey="1">Unisex</Dropdown.Item>
        <Dropdown.Item eventKey="1">Niños</Dropdown.Item>
        <Dropdown.Item eventKey="2">Niñas</Dropdown.Item>
      </DropdownButton>
    </ButtonGroup>
  );
}

 
export default Prueba;
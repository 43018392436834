const tablaMidot = [
  {
    id: 1,
    nombre: "Ahavá (Amor)",
    significado:
      "Cultivar un amor profundo y desinteresado hacia los demás, mostrando afecto y compasión.",
    comoTrabajarla:
      "Practicar el amor incondicional hacia los demás, mostrando actos de bondad y buscando el bienestar de quienes nos rodean.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, priorizando el amor y la compasión hacia los demás.",
    desequilibrio:
      "Falta de amor y compasión hacia los demás, falta de actos de bondad y dificultad para mostrar afecto.",
    destacado:
      "El amor es una midá fundamental que se debe cultivar en todas nuestras interacciones y relaciones, y puede manifestarse de diferentes maneras en nuestra vida diaria.",
  },
  {
    id: 2,
    nombre: "Savlanut (Paciencia)",
    significado:
      "Cultivar la capacidad de ser paciente y tolerante frente a las dificultades y los desafíos.",
    comoTrabajarla:
      "Practicar la paciencia en situaciones desafiantes, respirando profundamente y tomando pausas para mantener la calma.",
    cuandoTrabajarla:
      "Siempre, especialmente cuando nos enfrentamos a situaciones estresantes o cuando interactuamos con personas que nos desafían.",
    desequilibrio:
      "Falta de paciencia, impaciencia constante y reacciones impulsivas ante las dificultades.",
    destacado:
      "La paciencia es una cualidad importante para cultivar en nuestra vida diaria, ya que nos permite manejar situaciones estresantes con calma y comprensión. Practicar la paciencia nos ayuda a mantener relaciones armoniosas y a superar obstáculos con perseverancia.",
  },
  {
    id: 3,
    nombre: "Hakarat Hatov (Gratitud)",
    significado:
      "Cultivar un sentido de gratitud y aprecio por las bendiciones y las experiencias positivas en la vida.",
    comoTrabajarla:
      "Practicar la gratitud diariamente, expresando agradecimiento por las pequeñas cosas y reconociendo las bendiciones en nuestra vida.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y experiencias, recordando cultivar la gratitud y aprecio por lo que tenemos.",
    desequilibrio:
      "Falta de gratitud y aprecio por las bendiciones en la vida, falta de reconocimiento de las experiencias positivas.",
    destacado:
      "La gratitud es una midá poderosa que nos permite apreciar y valorar lo que tenemos en nuestras vidas. Practicar la gratitud nos ayuda a enfocarnos en lo positivo, cultivar la alegría y fortalecer nuestras relaciones. Es importante recordar expresar gratitud no solo hacia los demás, sino también hacia uno mismo y hacia lo divino.",
  },
  {
    id: 4,
    nombre: "Humildad",
    significado:
      "Reconocer nuestras limitaciones y aprender a mantenernos en un estado de apertura y humildad.",
    comoTrabajarla:
      "Escuchar a los demás con mente abierta, reconocer los propios errores y aprender de ellos, practicar la modestia.",
    cuandoTrabajarla:
      "En situaciones de conflicto, al recibir elogios o cuando se siente superioridad hacia los demás.",
    desequilibrio:
      "Orgullo excesivo, arrogancia o falta de apertura hacia diferentes perspectivas.",
  },
  {
    id: 5,
    nombre: "Perdón",
    significado:
      "Dejar ir resentimientos y rencores, liberando el corazón y permitiendo la reconciliación.",
    comoTrabajarla:
      "Practicar la compasión hacia uno mismo y hacia los demás, trabajar en la empatía y en liberar la necesidad de venganza.",
    cuandoTrabajarla:
      "Cuando se siente ira, resentimiento o se lleva cargas emocionales del pasado.",
    desequilibrio:
      "Rencor constante, incapacidad para perdonar o resentimientos que afectan negativamente las relaciones.",
  },
  {
    id: 6,
    nombre: "Generosidad",
    significado: "Compartir y dar libremente a los demás, tanto material como emocionalmente.",
    comoTrabajarla:
      "Practicar el dar desinteresadamente, ayudar a los necesitados y mostrar compasión hacia los demás.",
    cuandoTrabajarla:
      "Siempre que se tenga la oportunidad de brindar apoyo, compasión y compartir lo que se tiene.",
    desequilibrio: "Egoísmo, retener lo que se tiene y dificultad para compartir o ser generoso.",
  },
  {
    id: 7,
    nombre: "Tzedaká (Justicia y Caridad)",
    significado: "Buscar la justicia social y practicar la caridad hacia los necesitados.",
    comoTrabajarla:
      "Contribuir a obras benéficas, participar en proyectos de ayuda comunitaria y luchar por la igualdad.",
    cuandoTrabajarla:
      "Siempre, en todas las acciones y decisiones, para contribuir a la mejora y sanación del mundo.",
    desequilibrio:
      "Indiferencia hacia los problemas del mundo, falta de compromiso con la justicia y la armonía.",
  },
  {
    id: 8,
    nombre: "Ajavat Jésed (Amor y Bondad)",
    significado: "Mostrar amor y bondad hacia los demás, sin esperar nada a cambio.",
    comoTrabajarla:
      "Realizar actos de bondad, ser amable y compasivo en las relaciones con los demás.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones con los demás y al encontrarse con oportunidades de ayudar.",
    desequilibrio: "Falta de empatía y amor hacia los demás, egoísmo y falta de consideración.",
  },
  {
    id: 9,
    nombre: "Anavá (Humildad)",
    significado: "Reconocer nuestras limitaciones y mantener una actitud humilde hacia los demás.",
    comoTrabajarla:
      "Escuchar y aprender de los demás, reconocer los propios errores y ser consciente de las propias fortalezas y debilidades.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y relaciones, especialmente cuando se siente superioridad o arrogancia.",
    desequilibrio: "Orgullo excesivo, arrogancia y falta de respeto hacia los demás.",
  },
  {
    id: 10,
    nombre: "Rachamim (Compasión)",
    significado:
      "Sentir empatía y compasión hacia los demás, mostrando preocupación por su sufrimiento.",
    comoTrabajarla:
      "Ponerse en el lugar del otro, escuchar con compasión y actuar para aliviar el sufrimiento de los demás.",
    cuandoTrabajarla:
      "Siempre, al encontrarse con el sufrimiento de los demás y al enfrentar situaciones difíciles.",
    desequilibrio: "Falta de empatía y compasión, indiferencia hacia el sufrimiento de los demás.",
  },
  {
    id: 11,
    nombre: "Emuná (Fe)",
    significado: "Tener confianza y creencia en lo divino y en un poder superior.",
    comoTrabajarla: "Cultivar la espiritualidad, practicar la oración y confiar en un plan divino.",
    cuandoTrabajarla:
      "Siempre, para fortalecer la conexión con lo divino y enfrentar momentos de duda o incertidumbre.",
    desequilibrio: "Falta de fe, dificultad para confiar en lo divino y en un poder superior.",
  },
  {
    id: 12,
    nombre: "Bitajón (Confianza)",
    significado:
      "Tener confianza en uno mismo y en los demás, creer en la capacidad de superar los desafíos.",
    comoTrabajarla:
      "Desarrollar la autoconfianza, ser fiel a los compromisos y confiar en las habilidades y fortalezas de los demás.",
    cuandoTrabajarla:
      "Siempre, al enfrentar desafíos y momentos de incertidumbre, y en la construcción de relaciones basadas en la confianza.",
    desequilibrio:
      "Inseguridad, falta de confianza en uno mismo y en los demás, y dificultad para construir relaciones basadas en la confianza.",
  },
  {
    id: 13,
    nombre: "Hakarat Hatov (Agradecimiento)",
    significado: "Reconocer y apreciar las bendiciones y las buenas acciones de los demás.",
    comoTrabajarla:
      "Expresar gratitud y apreciación por las pequeñas y grandes cosas de la vida, y reconocer y agradecer a quienes nos brindan ayuda y apoyo.",
    cuandoTrabajarla: "Siempre, en todo momento y con todas las personas que nos rodean.",
    desequilibrio:
      "Ingratitud, falta de aprecio por las bendiciones y por las acciones de los demás.",
  },
  {
    id: 14,
    nombre: "Tikkun Olam (Reparación del Mundo)",
    significado:
      "Contribuir a la reparación y mejora del mundo, promoviendo la justicia y la armonía.",
    comoTrabajarla:
      "Participar en acciones que promuevan la justicia social, cuidar el medio ambiente y brindar ayuda a quienes lo necesitan.",
    cuandoTrabajarla:
      "Siempre, en todas las acciones y decisiones, para contribuir a la mejora y sanación del mundo.",
    desequilibrio:
      "Indiferencia hacia los problemas del mundo, falta de compromiso con la justicia y la armonía.",
  },
  {
    id: 15,
    nombre: "Sabiduría",
    significado:
      "Buscar conocimiento y comprensión profunda de las verdades espirituales y de la vida.",
    comoTrabajarla:
      "Estudiar y aprender de textos sagrados, reflexionar y buscar la sabiduría en la experiencia y el aprendizaje continuo.",
    cuandoTrabajarla:
      "Siempre, en la búsqueda constante de conocimiento y en la toma de decisiones.",
    desequilibrio:
      "Falta de interés en la adquisición de conocimiento y dificultad para discernir la sabiduría en las decisiones y acciones.",
  },
  {
    id: 16,
    nombre: "Disciplina",
    significado:
      "Establecer y mantener hábitos y rutinas que contribuyan al crecimiento espiritual y personal.",
    comoTrabajarla:
      "Establecer metas y objetivos claros, desarrollar una rutina de práctica espiritual y mantener el compromiso con las responsabilidades.",
    cuandoTrabajarla:
      "Siempre, en la búsqueda constante de disciplina en todas las áreas de la vida.",
    desequilibrio:
      "Falta de autodisciplina, falta de constancia en las prácticas y dificultad para cumplir con las responsabilidades.",
  },
  {
    id: 17,
    nombre: "Perdón",
    significado:
      "Dejar ir resentimientos y rencores, liberando el corazón y permitiendo la reconciliación.",
    comoTrabajarla:
      "Practicar la compasión hacia uno mismo y hacia los demás, trabajar en la empatía y en liberar la necesidad de venganza.",
    cuandoTrabajarla:
      "Cuando se siente ira, resentimiento o se lleva cargas emocionales del pasado.",
    desequilibrio:
      "Rencor constante, incapacidad para perdonar o resentimientos que afectan negativamente las relaciones.",
  },
  {
    id: 18,
    nombre: "Paciencia",
    significado:
      "Tener la capacidad de esperar con calma y aceptar las situaciones sin frustración.",
    comoTrabajarla:
      "Practicar la respiración consciente, ser comprensivo y compasivo, buscar perspectivas diferentes.",
    cuandoTrabajarla: "Cuando las cosas no salen como se espera o cuando hay demoras y obstáculos.",
    desequilibrio:
      "Impaciencia, frustración constante o incapacidad para lidiar con situaciones estresantes.",
  },
  {
    id: 19,
    nombre: "Generosidad",
    significado: "Compartir y dar libremente a los demás, tanto material como emocionalmente.",
    comoTrabajarla:
      "Practicar el dar desinteresadamente, ayudar a los necesitados y mostrar compasión hacia los demás.",
    cuandoTrabajarla:
      "Siempre que se tenga la oportunidad de brindar apoyo, compasión y compartir lo que se tiene.",
    desequilibrio: "Egoísmo, retener lo que se tiene y dificultad para compartir o ser generoso.",
  },
  {
    id: 20,
    nombre: "Integridad",
    significado:
      "Actuar de acuerdo con los valores y principios morales, manteniendo la honestidad y la coherencia.",
    comoTrabajarla:
      "Ser fiel a los principios personales, mantener la honestidad y actuar con coherencia en todas las situaciones.",
    cuandoTrabajarla:
      "Siempre, en todas las acciones y decisiones, incluso cuando enfrenta desafíos éticos.",
    desequilibrio:
      "Falta de integridad, actuar de manera deshonesta o incoherente con los valores personales.",
  },
  {
    id: 21,
    nombre: "Responsabilidad",
    significado:
      "Aceptar las consecuencias de las propias acciones y cumplir con los compromisos asumidos.",
    comoTrabajarla:
      "Ser consciente de las propias responsabilidades, cumplir con los compromisos y tomar acciones para resolver problemas.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y relaciones, especialmente cuando se enfrenta a desafíos o dificultades.",
    desequilibrio:
      "Falta de responsabilidad, evasión de compromisos y dificultad para asumir las consecuencias de las acciones.",
  },
  {
    id: 22,
    nombre: "Honestidad",
    significado: "Decir la verdad y actuar de manera sincera y transparente.",
    comoTrabajarla:
      "Ser honesto en todas las comunicaciones, decir la verdad y actuar de manera sincera y transparente.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y relaciones, especialmente cuando se enfrenta a dilemas éticos.",
    desequilibrio:
      "Falta de honestidad, mentiras o engaños constantes, falta de transparencia en las acciones.",
  },
  {
    id: 23,
    nombre: "Respeto",
    significado:
      "Tratar a los demás con consideración y dignidad, reconociendo su valor y sus derechos.",
    comoTrabajarla:
      "Escuchar activamente, ser respetuoso con las opiniones y diferencias de los demás, y tratar a todos con consideración y dignidad.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, fomentando un ambiente de respeto mutuo.",
    desequilibrio:
      "Falta de respeto, trato irrespetuoso hacia los demás y falta de consideración por sus derechos y opiniones.",
  },
  {
    id: 24,
    nombre: "Lealtad",
    significado: "Ser fiel y comprometido con las personas y los valores importantes en la vida.",
    comoTrabajarla:
      "Mantener la lealtad hacia las personas cercanas, honrar los compromisos y estar presente en las relaciones importantes.",
    cuandoTrabajarla:
      "Siempre, en todas las relaciones significativas y al enfrentar decisiones que requieren fidelidad.",
    desequilibrio:
      "Falta de lealtad, falta de compromiso o traición hacia las personas o los valores importantes.",
  },

  {
    id: 25,
    nombre: "Bitul (Humildad radical)",
    significado:
      "Reconocer que todo proviene de lo divino y renunciar al ego y a la necesidad de control.",
    comoTrabajarla:
      "Practicar la rendición al poder superior, dejar de lado el ego y aceptar la voluntad divina.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones, especialmente cuando se enfrenta a la necesidad de controlar o dominar.",
    desequilibrio: "Egoísmo excesivo, necesidad de controlar todo y falta de humildad.",
  },
  {
    id: 26,
    nombre: "Hitbodedut (Oración en soledad)",
    significado:
      "Practicar la introspección y la comunicación íntima con lo divino en la soledad y el silencio.",
    comoTrabajarla:
      "Dedicar tiempo regular para la oración en soledad, meditar y buscar una conexión profunda con lo divino.",
    cuandoTrabajarla:
      "En momentos de reflexión personal, al buscar orientación y en la búsqueda de una mayor conexión espiritual.",
    desequilibrio:
      "Falta de introspección, dificultad para conectar con lo divino en la soledad y falta de tiempo dedicado a la oración personal.",
  },
  {
    id: 27,
    nombre: "Histapkut (Simplicidad)",
    significado:
      "Vivir de manera sencilla y sin apegos materiales, valorando más la espiritualidad y las relaciones auténticas.",
    comoTrabajarla:
      "Simplificar el estilo de vida, deshacerse de lo innecesario y enfocarse en lo esencial, cultivar relaciones significativas.",
    cuandoTrabajarla:
      "Siempre, en todas las decisiones relacionadas con el consumo y en la búsqueda de una vida más equilibrada y centrada en lo espiritual.",
    desequilibrio:
      "Apego excesivo a los bienes materiales, búsqueda constante de satisfacción externa y falta de valoración de lo espiritual.",
  },
  {
    id: 28,
    nombre: "Shomer Halashon (Cuidado del habla)",
    significado:
      "Ser consciente y cuidadoso con las palabras que se expresan, evitando el chisme, la difamación y las palabras hirientes.",
    comoTrabajarla:
      "Practicar el pensar antes de hablar, ser consciente del impacto de las palabras y cultivar un lenguaje positivo y constructivo.",
    cuandoTrabajarla:
      "En todas las interacciones y conversaciones, especialmente cuando se siente la tentación de hablar negativamente de otros o de difamar.",
    desequilibrio:
      "Chismes constantes, palabras hirientes y falta de cuidado en la forma de expresarse.",
  },
  {
    id: 29,
    nombre: "Netzaj (Persistencia)",
    significado:
      "Mantenerse firme y perseverar en la búsqueda de metas y valores, incluso frente a los desafíos y obstáculos.",
    comoTrabajarla:
      "Desarrollar la determinación y la resistencia, establecer metas claras y superar los obstáculos con perseverancia.",
    cuandoTrabajarla:
      "Cuando se enfrenta a desafíos y dificultades, al perseguir metas personales y al mantener el compromiso con los valores.",
    desequilibrio:
      "Desmotivación constante, falta de perseverancia y dificultad para enfrentar y superar los desafíos.",
  },
  {
    id: 30,
    nombre: "Simjá (Alegría)",
    significado:
      "Cultivar una actitud positiva y estar alegre en todas las circunstancias, reconociendo las bendiciones y disfrutando de la vida.",
    comoTrabajarla:
      "Practicar la gratitud, buscar la belleza en las pequeñas cosas y cultivar una perspectiva positiva ante los desafíos.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones, especialmente cuando se enfrenta a la adversidad y se necesita encontrar fuerza y esperanza.",
    desequilibrio:
      "Pesimismo constante, falta de alegría y dificultad para encontrar satisfacción en la vida.",
  },
  {
    id: 31,
    nombre: "Shalom (Paz)",
    significado:
      "Cultivar la armonía y la paz interna y externa, buscando la reconciliación y la resolución pacífica de conflictos.",
    comoTrabajarla:
      "Practicar la escucha activa, buscar la reconciliación y fomentar un ambiente de paz y armonía en todas las relaciones.",
    cuandoTrabajarla:
      "Siempre, especialmente en situaciones de conflicto y al enfrentar desafíos en las relaciones.",
    desequilibrio: "Constante conflicto y falta de paz interna o externa.",
  },
  {
    id: 32,
    nombre: "Hitbodedut (Reflexión personal)",
    significado:
      "Dedicar tiempo regular para la reflexión y la conexión con el ser interno, permitiendo un mayor autoconocimiento y crecimiento espiritual.",
    comoTrabajarla:
      "Establecer momentos de soledad y silencio para la introspección, el autoanálisis y la búsqueda de claridad y dirección en la vida.",
    cuandoTrabajarla:
      "En momentos de toma de decisiones importantes, cuando se necesita claridad y cuando se busca un mayor autoconocimiento.",
    desequilibrio:
      "Falta de autoconocimiento, falta de conexión con el ser interno y dificultad para tomar decisiones alineadas con los valores y propósito.",
  },
  {
    id: 33,
    nombre: "Hakarat Hatov (Reconocimiento del bien)",
    significado:
      "Reconocer y apreciar los actos de bondad y las bendiciones que se reciben en la vida.",
    comoTrabajarla:
      "Cultivar la gratitud, expresar aprecio hacia los demás y practicar el reconocimiento de las bendiciones diarias.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y relaciones, especialmente cuando se tiende a enfocarse en lo negativo.",
    desequilibrio:
      "Ingratitud, falta de aprecio por las bendiciones y dificultad para reconocer los actos de bondad de los demás.",
  },
  {
    id: 34,
    nombre: "Ikvut Hashemesh (Seguir los pasos divinos)",
    significado:
      "Buscar alinearse con la voluntad divina y seguir los caminos trazados por lo divino.",
    comoTrabajarla:
      "Practicar la obediencia a los preceptos divinos, estudiar y seguir las enseñanzas sagradas y buscar la guía de lo divino en todas las decisiones.",
    cuandoTrabajarla:
      "Siempre, en todas las decisiones y acciones, para buscar la alineación con la voluntad divina.",
    desequilibrio:
      "Falta de conexión con lo divino, falta de obediencia a los preceptos y dificultad para seguir los caminos trazados por lo divino.",
  },
  {
    id: 35,
    nombre: "Kavod (Honor)",
    significado: "Tratar a los demás con respeto y dignidad, reconociendo su valor inherente.",
    comoTrabajarla:
      "Mostrar consideración y respeto hacia los demás, reconocer sus logros y habilidades, y valorar su dignidad.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, especialmente cuando se siente la tentación de menospreciar o desvalorizar a los demás.",
    desequilibrio: "Falta de respeto hacia los demás, desprecio o menosprecio constante.",
  },
  {
    id: 36,
    nombre: "Zrizut (Diligencia)",
    significado:
      "Actuar con prontitud y diligencia en la realización de las tareas y responsabilidades.",
    comoTrabajarla:
      "Ser proactivo, cumplir con los plazos y demostrar responsabilidad en la finalización de las tareas asignadas.",
    cuandoTrabajarla:
      "Siempre, en todas las actividades y responsabilidades, especialmente cuando se siente la tendencia a la procrastinación.",
    desequilibrio:
      "Procrastinación constante, falta de diligencia y dificultad para cumplir con las tareas y responsabilidades.",
  },
  {
    id: 37,
    nombre: "Hishtavut (Equilibrio)",
    significado:
      "Buscar el equilibrio y la armonía en todas las áreas de la vida, evitando los extremos.",
    comoTrabajarla:
      "Identificar las áreas desequilibradas de la vida, establecer límites y buscar una distribución adecuada de tiempo y energía.",
    cuandoTrabajarla:
      "Siempre, en la evaluación constante de las prioridades y la búsqueda del equilibrio en todas las áreas de la vida.",
    desequilibrio:
      "Desigualdad en las áreas de la vida, dificultad para establecer límites y tendencia a caer en extremos.",
  },
  {
    id: 38,
    nombre: "Hachnasat Orchim (Hospitalidad)",
    significado:
      "Recibir a los demás con amabilidad y generosidad, abriendo las puertas y brindando hospitalidad.",
    comoTrabajarla:
      "Practicar la generosidad al recibir a los demás, mostrar interés genuino y brindar apoyo y comodidad a los invitados.",
    cuandoTrabajarla:
      "Siempre, en todas las oportunidades de recibir a los demás y en el trato con los visitantes y huéspedes.",
    desequilibrio:
      "Falta de hospitalidad, falta de consideración hacia los demás y dificultad para abrirse a los demás.",
  },
  {
    id: 39,
    nombre: "Talmid Chacham (Estudiante sabio)",
    significado:
      "Buscar el conocimiento y la sabiduría a través del estudio y el aprendizaje continuo.",
    comoTrabajarla:
      "Dedicar tiempo al estudio de textos sagrados, buscar la enseñanza de sabios y cultivar una actitud de aprendizaje constante.",
    cuandoTrabajarla:
      "Siempre, en la búsqueda constante de conocimiento y en la voluntad de aprender de otros.",
    desequilibrio:
      "Falta de interés en el aprendizaje, falta de búsqueda de conocimiento y falta de sabiduría en las acciones y decisiones.",
  },
  {
    id: 40,
    nombre: "Bitajón (Confianza en lo divino)",
    significado:
      "Tener confianza en lo divino y en el plan divino, creyendo en que todo sucede por una razón.",
    comoTrabajarla:
      "Cultivar la confianza en lo divino, buscar señales y sincronías, y entregar las preocupaciones y ansiedades a lo divino.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, especialmente cuando se siente la incertidumbre o el miedo.",
    desequilibrio:
      "Falta de confianza en lo divino, preocupación excesiva y dificultad para soltar el control.",
  },
  {
    id: 41,
    nombre: "Kavannah (Intención)",
    significado:
      "Dirigir la atención y la intención hacia las acciones y las prácticas espirituales, infundiendo significado y propósito.",
    comoTrabajarla:
      "Ser consciente de la intención detrás de las acciones y las prácticas, cultivar una intención clara y significativa.",
    cuandoTrabajarla:
      "Siempre, en todas las acciones y prácticas espirituales, para infundir significado y propósito.",
    desequilibrio:
      "Falta de intención consciente, realizar acciones de manera mecánica o sin sentido.",
  },
  {
    id: 42,
    nombre: "Bitul Hayesh (Anulación del ego)",
    significado:
      "Superar el ego y el sentido de la individualidad, reconociendo la unidad y la interconexión con lo divino y con los demás.",
    comoTrabajarla:
      "Practicar la humildad, dejar de lado el ego y cultivar una visión más amplia y desapegada de la identidad personal.",
    cuandoTrabajarla:
      "Siempre, en la búsqueda de una conexión más profunda con lo divino y en la relación con los demás.",
    desequilibrio:
      "Egoísmo excesivo, sentido de superioridad o inferioridad, falta de conexión con lo divino y los demás.",
  },
  {
    id: 43,
    nombre: "Hachaná (Preparación)",
    significado:
      "Prepararse adecuadamente y con anticipación para los desafíos y las oportunidades que se presentan en la vida.",
    comoTrabajarla:
      "Ser proactivo, anticipar situaciones y prepararse mental, emocional y físicamente para ellas.",
    cuandoTrabajarla:
      "Siempre, en la preparación constante para las situaciones de la vida y para aprovechar las oportunidades que se presentan.",
    desequilibrio:
      "Falta de preparación, falta de anticipación y dificultad para aprovechar las oportunidades que se presentan.",
  },
  {
    id: 44,
    nombre: "Hesed Shel Emes (Compasión genuina)",
    significado:
      "Cultivar una compasión sincera y genuina hacia los demás, mostrando empatía y buscando su bienestar.",
    comoTrabajarla:
      "Practicar la empatía, estar presente para los demás y mostrar actos de bondad y apoyo genuinos.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, especialmente cuando se enfrenta a las dificultades de los demás.",
    desequilibrio:
      "Falta de compasión, falta de empatía y dificultad para mostrar actos de bondad y apoyo genuinos.",
  },
  {
    id: 45,
    nombre: "Hitbodedut (Conexión personal)",
    significado:
      "Establecer una conexión personal y directa con lo divino a través de la oración y la meditación en soledad.",
    comoTrabajarla:
      "Dedicar tiempo a la oración y la meditación en soledad, buscando una conexión directa y personal con lo divino.",
    cuandoTrabajarla:
      "Regularmente, en momentos de búsqueda de una mayor conexión espiritual y orientación divina.",
    desequilibrio:
      "Falta de conexión personal con lo divino, dificultad para encontrar momentos de soledad y silencio para la oración y la meditación.",
  },
  {
    id: 46,
    nombre: "Anivut (Modestia)",
    significado:
      "Mantener una actitud modesta y humilde frente a los logros y reconocimientos personales.",
    comoTrabajarla:
      "Evitar la vanidad y la ostentación, reconocer y valorar las habilidades y logros sin caer en la soberbia.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y relaciones, especialmente cuando se enfrenta a los elogios o a situaciones de reconocimiento personal.",
    desequilibrio:
      "Soberbia, arrogancia y falta de humildad frente a los logros y reconocimientos personales.",
  },
  {
    id: 47,
    nombre: "Dveikut (Unión con lo divino)",
    significado:
      "Buscar una unión íntima y cercana con lo divino, cultivando una relación profunda y personal.",
    comoTrabajarla:
      "Dedicar tiempo a la oración, el estudio y las prácticas espirituales para fortalecer la relación con lo divino.",
    cuandoTrabajarla:
      "Siempre, en la búsqueda constante de una mayor conexión y unión con lo divino.",
    desequilibrio:
      "Falta de conexión con lo divino, falta de espiritualidad y dificultad para establecer una relación cercana y personal con lo divino.",
  },
  {
    id: 48,
    nombre: "Avinut (Responsabilidad)",
    significado:
      "Aceptar la responsabilidad de las propias acciones y decisiones, asumiendo las consecuencias correspondientes.",
    comoTrabajarla:
      "Ser consciente de las propias responsabilidades, actuar de manera ética y tomar decisiones informadas y responsables.",
    cuandoTrabajarla:
      "Siempre, en todas las acciones y decisiones, asumiendo la responsabilidad de las consecuencias.",
    desequilibrio:
      "Falta de responsabilidad, evadir las consecuencias de las propias acciones y decisiones.",
  },
  {
    id: 49,
    nombre: "Kriat Shema (Recitación del Shemá)",
    significado:
      "Recitar el Shemá, el principio fundamental del judaísmo que declara la unicidad de lo divino.",
    comoTrabajarla:
      "Recitar el Shemá diariamente con devoción y entendimiento, conectándose con su significado y aplicándolo en la vida diaria.",
    cuandoTrabajarla:
      "Diariamente, al recitar el Shemá y al reflexionar sobre su significado y mensaje.",
    desequilibrio:
      "Falta de conexión con el Shemá, falta de reflexión sobre su significado y falta de aplicación en la vida diaria.",
  },
  {
    id: 50,
    nombre: "Simjat Jaim (Alegría de vivir)",
    significado:
      "Cultivar una actitud alegre y positiva hacia la vida, encontrando gozo en las bendiciones diarias.",
    comoTrabajarla:
      "Cultivar la gratitud, enfocarse en las cosas positivas, buscar la belleza en la vida y disfrutar de las bendiciones diarias.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y circunstancias, cultivando una actitud alegre y positiva.",
    desequilibrio:
      "Pesimismo, falta de alegría y dificultad para encontrar satisfacción y gozo en la vida.",
  },
  {
    id: 51,
    nombre: "Kedushá (Santidad)",
    significado: "Buscar la santidad y la conexión con lo divino en todas las áreas de la vida.",
    comoTrabajarla:
      "Practicar acciones sagradas, cultivar una vida espiritual y buscar la elevación en todas las experiencias.",
    cuandoTrabajarla:
      "Siempre, en todas las acciones y decisiones, en busca de la conexión con lo divino y la manifestación de la santidad.",
    desequilibrio:
      "Falta de espiritualidad, falta de búsqueda de la santidad y falta de conexión con lo divino.",
  },
  {
    id: 52,
    nombre: "Igul HaChesed (Círculo de bondad)",
    significado:
      "Crear un círculo de bondad y generosidad, extendiendo actos de bondad hacia los demás y promoviendo un impacto positivo en la sociedad.",
    comoTrabajarla:
      "Practicar actos de bondad, ayudar a los demás y fomentar una cultura de generosidad y apoyo mutuo.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, buscando oportunidades para brindar ayuda y promover el bienestar de los demás.",
    desequilibrio:
      "Falta de empatía y generosidad, falta de actos de bondad y dificultad para impactar positivamente a los demás y a la sociedad.",
  },
  {
    id: 53,
    nombre: "Shomer HaBeriyot (Cuidado de la creación)",
    significado:
      "Cultivar un cuidado y respeto por la creación y el medio ambiente, reconociendo la importancia de preservar y proteger la naturaleza.",
    comoTrabajarla:
      "Adoptar prácticas sostenibles, reducir el impacto ambiental y promover el cuidado y la conservación de la naturaleza.",
    cuandoTrabajarla:
      "Siempre, en todas las acciones y decisiones, considerando el impacto en el medio ambiente y tomando medidas para proteger la creación.",
    desequilibrio:
      "Falta de consideración por el medio ambiente, prácticas insostenibles y falta de responsabilidad hacia la creación.",
  },
  {
    id: 54,
    nombre: "Kabbalat Ol Maljut Shamayim (Aceptación del yugo divino)",
    significado:
      "Aceptar el dominio y la voluntad de lo divino sobre la vida, reconociendo la autoridad y confiando en la guía divina.",
    comoTrabajarla:
      "Cultivar una actitud de sumisión y aceptación de lo divino, confiando en que la voluntad divina es lo mejor.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, especialmente cuando se enfrenta a la incertidumbre y a los desafíos.",
    desequilibrio:
      "Resistencia constante a la voluntad divina, falta de confianza en lo divino y dificultad para aceptar la autoridad de lo divino.",
  },
  {
    id: 55,
    nombre: "Mevakesh Hashem (Búsqueda de lo divino)",
    significado:
      "Buscar y anhelar una conexión profunda con lo divino, deseando acercarse y encontrar un propósito espiritual.",
    comoTrabajarla:
      "Dedicar tiempo a la búsqueda espiritual, explorar diferentes prácticas y estudiar las enseñanzas sagradas.",
    cuandoTrabajarla:
      "Siempre, en la búsqueda constante de una conexión más profunda con lo divino y en la exploración de la espiritualidad.",
    desequilibrio:
      "Falta de búsqueda espiritual, falta de anhelo por una conexión más profunda con lo divino y falta de propósito espiritual.",
  },
  {
    id: 56,
    nombre: "Limud Torah (Estudio de la Torá)",
    significado: "Dedicarse al estudio y la exploración de la Torá y las enseñanzas sagradas.",
    comoTrabajarla:
      "Establecer tiempo dedicado al estudio de la Torá, adquirir conocimiento sagrado y reflexionar sobre las enseñanzas.",
    cuandoTrabajarla:
      "Regularmente, al dedicar tiempo al estudio de la Torá y la profundización en las enseñanzas sagradas.",
    desequilibrio:
      "Falta de interés en el estudio de la Torá, falta de conocimiento sagrado y falta de conexión con las enseñanzas divinas.",
  },
  {
    id: 57,
    nombre: "Tzedaká (Justicia y caridad)",
    significado:
      "Practicar la justicia y la caridad, ayudando a los necesitados y promoviendo la equidad en la sociedad.",
    comoTrabajarla:
      "Brindar apoyo a los necesitados, contribuir a causas justas y promover la igualdad y la equidad.",
    cuandoTrabajarla:
      "Siempre, en todas las oportunidades de ayudar a los demás y promover la justicia y la equidad.",
    desequilibrio:
      "Falta de compasión y generosidad, falta de apoyo a los necesitados y falta de compromiso con causas justas.",
  },
  {
    id: 58,
    nombre: "Ajavat Jésed (Amor y bondad)",
    significado:
      "Cultivar el amor y la bondad hacia los demás, mostrando actos de amor desinteresado y generosidad.",
    comoTrabajarla:
      "Mostrar actos de bondad y amor hacia los demás, cultivar una actitud de generosidad y empatía.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, buscando oportunidades para mostrar amor y bondad hacia los demás.",
    desequilibrio:
      "Falta de amor y bondad hacia los demás, falta de generosidad y empatía hacia los demás.",
  },
  {
    id: 59,
    nombre: "Anavá (Humildad)",
    significado:
      "Cultivar una actitud de humildad, reconociendo que todas las personas son igualmente valiosas y dignas.",
    comoTrabajarla:
      "Evitar el orgullo y la arrogancia, reconocer las habilidades y logros de los demás y mostrar respeto hacia todos.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y relaciones, cultivando una actitud humilde y respetuosa hacia los demás.",
    desequilibrio: "Orgullo excesivo, arrogancia y falta de respeto hacia los demás.",
  },
  {
    id: 60,
    nombre: "Rachamim (Compasión)",
    significado:
      "Cultivar la compasión hacia los demás, mostrando empatía y cuidado por su sufrimiento y dificultades.",
    comoTrabajarla:
      "Mostrar compasión y empatía hacia los demás, brindar apoyo emocional y buscar aliviar el sufrimiento de los demás.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y relaciones, especialmente cuando se enfrenta a la necesidad y el sufrimiento de los demás.",
    desequilibrio:
      "Falta de compasión, falta de empatía y falta de apoyo emocional hacia los demás.",
  },
  {
    id: 61,
    nombre: "Emuná (Fe)",
    significado:
      "Tener fe y confianza en lo divino, creyendo en la providencia y en el plan divino.",
    comoTrabajarla:
      "Cultivar la fe a través de la reflexión, la práctica espiritual y la confianza en las promesas divinas.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, especialmente cuando se enfrenta a la incertidumbre y a los desafíos.",
    desequilibrio:
      "Falta de fe, falta de confianza en lo divino y dificultad para creer en la providencia.",
  },
  {
    id: 62,
    nombre: "Bitajón (Confianza)",
    significado:
      "Tener confianza en uno mismo y en las propias capacidades, creyendo en el propio potencial.",
    comoTrabajarla:
      "Cultivar la confianza en uno mismo, reconocer las fortalezas y logros personales, y enfrentar los desafíos con valentía.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, especialmente cuando se enfrenta a la duda y a la inseguridad.",
    desequilibrio:
      "Falta de confianza en uno mismo, baja autoestima y dificultad para enfrentar los desafíos con valentía.",
  },
  {
    id: 63,
    nombre: "Tzedek (Justicia)",
    significado:
      "Buscar la justicia y la equidad en todas las áreas de la vida, promoviendo el respeto y la igualdad.",
    comoTrabajarla:
      "Practicar la justicia en las relaciones y las acciones, ser imparcial y promover la igualdad de derechos y oportunidades.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, especialmente cuando se enfrenta a la injusticia y a la desigualdad.",
    desequilibrio:
      "Falta de justicia, discriminación y falta de respeto hacia los derechos y la dignidad de los demás.",
  },
  {
    id: 64,
    nombre: "Boré Olam (Creador del mundo)",
    significado:
      "Reconocer y honrar a lo divino como el Creador del mundo, mostrando reverencia y gratitud.",
    comoTrabajarla:
      "Cultivar una actitud de reverencia hacia lo divino, expresar gratitud y reconocer la presencia de lo divino en la vida.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y experiencias, cultivando la conciencia de la presencia divina.",
    desequilibrio:
      "Falta de reconocimiento y gratitud hacia lo divino, falta de conexión con la espiritualidad y la presencia divina.",
  },
  {
    id: 65,
    nombre: "Shalom (Paz)",
    significado:
      "Buscar la paz en todas las relaciones y situaciones, cultivando la armonía y la reconciliación.",
    comoTrabajarla:
      "Promover la paz a través de la comunicación pacífica, la resolución de conflictos y la empatía hacia los demás.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, especialmente cuando se enfrenta a conflictos y tensiones.",
    desequilibrio:
      "Falta de paz, conflictos constantes y dificultad para encontrar armonía en las relaciones.",
  },
  {
    id: 66,
    nombre: "Hodaá (Agradecimiento)",
    significado:
      "Cultivar la gratitud por las bendiciones y experiencias de la vida, reconociendo y agradeciendo lo divino.",
    comoTrabajarla:
      "Practicar la gratitud diariamente, expresando agradecimiento por las bendiciones y las experiencias positivas.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y experiencias, cultivando una actitud de gratitud.",
    desequilibrio:
      "Falta de gratitud, falta de apreciación por las bendiciones y dificultad para reconocer y agradecer lo divino.",
  },
  {
    id: 67,
    nombre: "Shmirat Lashon (Cuidado del habla)",
    significado:
      "Ser consciente y cuidadoso con las palabras que se utilizan, evitando el chisme, la difamación y el lenguaje ofensivo.",
    comoTrabajarla:
      "Practicar la comunicación positiva, ser consciente de las palabras y pensar antes de hablar.",
    cuandoTrabajarla:
      "Siempre, en todas las conversaciones y situaciones de comunicación, cuidando el habla.",
    desequilibrio:
      "Uso irresponsable del habla, chismes, difamación y falta de consideración hacia los demás en la comunicación.",
  },
  {
    id: 68,
    nombre: "Hispailut (Auto-reflexión)",
    significado:
      "Realizar una introspección profunda y reflexionar sobre las propias acciones, emociones y pensamientos.",
    comoTrabajarla:
      "Dedicar tiempo regularmente a la auto-reflexión, examinar las acciones y buscar áreas de mejora personal.",
    cuandoTrabajarla:
      "Regularmente, en momentos de quietud y soledad, para evaluar y ajustar el propio comportamiento y mentalidad.",
    desequilibrio:
      "Falta de auto-reflexión, falta de conciencia de las propias acciones y falta de disposición para el crecimiento personal.",
  },
  {
    id: 69,
    nombre: "Hitchadshut (Renovación)",
    significado:
      "Buscar la renovación y el crecimiento personal, superando las limitaciones y buscando nuevas oportunidades.",
    comoTrabajarla:
      "Abrirse a nuevas experiencias, aprender nuevas habilidades y estar dispuesto a salir de la zona de confort.",
    cuandoTrabajarla:
      "Siempre, en la búsqueda constante de crecimiento personal y en momentos de estancamiento o rutina.",
    desequilibrio:
      "Estancamiento personal, resistencia al cambio y falta de disposición para buscar nuevas oportunidades de crecimiento.",
  },
  {
    id: 70,
    nombre: "Kedushat Hamishpajá (Santidad en la familia)",
    significado:
      "Cultivar la santidad en la vida familiar, promoviendo la armonía, el respeto y el amor entre los miembros de la familia.",
    comoTrabajarla:
      "Crear un ambiente de amor y respeto en la familia, promover la comunicación abierta y la comprensión mutua.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones familiares, priorizando la armonía y el bienestar de la familia.",
    desequilibrio:
      "Falta de armonía en la familia, falta de respeto y dificultad para promover el amor y la comprensión mutua.",
  },
  {
    id: 71,
    nombre: "Tiferet (Armonía)",
    significado:
      "Cultivar la armonía y el equilibrio en todas las áreas de la vida, integrando las diferentes partes de uno mismo.",
    comoTrabajarla:
      "Buscar un equilibrio entre diferentes aspectos de la vida, practicar la autenticidad y la integración.",
    cuandoTrabajarla:
      "Siempre, en la búsqueda de la armonía interna y externa, especialmente cuando se enfrenta a desequilibrios.",
    desequilibrio:
      "Desequilibrio interno, falta de armonía en las diferentes áreas de la vida y dificultad para integrar diferentes aspectos de uno mismo.",
  },
  {
    id: 72,
    nombre: "Ahavat Israel (Amor hacia el pueblo de Israel)",
    significado:
      "Cultivar el amor y la conexión con el pueblo de Israel, mostrando solidaridad y apoyo mutuo.",
    comoTrabajarla:
      "Promover la unidad y la solidaridad con el pueblo de Israel, mostrar respeto y apoyo mutuo.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones con miembros del pueblo de Israel, buscando la unidad y la conexión.",
    desequilibrio:
      "Falta de conexión y apoyo hacia el pueblo de Israel, falta de solidaridad y respeto mutuo.",
  },
  {
    id: 73,
    nombre: "Hachnasat Orchim (Hospitalidad)",
    significado: "Practicar la hospitalidad, acogiendo a los demás con amabilidad y generosidad.",
    comoTrabajarla:
      "Abrir las puertas a los demás, mostrar amabilidad y brindar apoyo a quienes lo necesiten.",
    cuandoTrabajarla:
      "Siempre, en todas las oportunidades de acoger a los demás, tanto en el hogar como en la comunidad.",
    desequilibrio:
      "Falta de hospitalidad, falta de amabilidad hacia los demás y dificultad para abrirse a los demás.",
  },
  {
    id: 74,
    nombre: "Shmirat Haguf (Cuidado del cuerpo)",
    significado:
      "Cuidar el cuerpo como un templo sagrado, promoviendo la salud física y el bienestar.",
    comoTrabajarla:
      "Adoptar hábitos saludables, cuidar la alimentación, hacer ejercicio y descansar adecuadamente.",
    cuandoTrabajarla:
      "Siempre, en todas las decisiones relacionadas con la salud y el bienestar del cuerpo.",
    desequilibrio:
      "Descuido del cuerpo, falta de cuidado de la salud física y falta de atención a las necesidades del cuerpo.",
  },
  {
    id: 75,
    nombre: "Bitul (Anulación del ego)",
    significado:
      "Anular el ego y cultivar la humildad, reconociendo que todo proviene de lo divino.",
    comoTrabajarla:
      "Practicar la humildad, reconocer los propios errores y limitaciones, y mostrar respeto hacia los demás.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y relaciones, especialmente cuando se enfrenta al orgullo y a la arrogancia.",
    desequilibrio: "Orgullo excesivo, falta de humildad y falta de respeto hacia los demás.",
  },
  {
    id: 76,
    nombre: "Avinut (Responsabilidad)",
    significado:
      "Ser responsable de las propias acciones y decisiones, asumiendo las consecuencias de los actos.",
    comoTrabajarla:
      "Tomar responsabilidad por las acciones y decisiones, cumplir con los compromisos y actuar con integridad.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, priorizando la responsabilidad personal.",
    desequilibrio:
      "Falta de responsabilidad, falta de cumplimiento de los compromisos y falta de asunción de las consecuencias de los actos.",
  },
  {
    id: 77,
    nombre: "Shmirat Einayim (Cuidado de la mirada)",
    significado:
      "Cuidar lo que se ve y evitar la mirada inapropiada y el consumo de contenido negativo.",
    comoTrabajarla:
      "Practicar la moderación en la exposición visual, evitar miradas lascivas y buscar contenido positivo y edificante.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones relacionadas con la exposición visual y el consumo de medios.",
    desequilibrio:
      "Mirada inapropiada, consumo excesivo de contenido negativo y falta de cuidado en lo que se ve.",
  },
  {
    id: 78,
    nombre: "Ahavat Haaretz (Amor a la tierra de Israel)",
    significado:
      "Cultivar el amor y el apego a la tierra de Israel, reconociendo su importancia y valor espiritual.",
    comoTrabajarla:
      "Mostrar respeto y amor por la tierra de Israel, apoyar su desarrollo y mantener una conexión espiritual con ella.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y pensamientos relacionados con la tierra de Israel.",
    desequilibrio:
      "Falta de conexión y aprecio por la tierra de Israel, falta de respeto hacia su importancia espiritual y cultural.",
  },
  {
    id: 79,
    nombre: "Hakarat Hatov Le'adam (Reconocimiento del bien en los demás)",
    significado:
      "Reconocer y apreciar las cualidades y acciones positivas de los demás, mostrando gratitud y respeto.",
    comoTrabajarla:
      "Buscar y reconocer las virtudes y acciones positivas de los demás, expresando gratitud y elogio.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, buscando oportunidades para reconocer y apreciar el bien en los demás.",
    desequilibrio:
      "Falta de reconocimiento y gratitud hacia los demás, falta de aprecio por sus virtudes y acciones positivas.",
  },
  {
    id: 80,
    nombre: "Shmirat Brit (Cuidado del pacto)",
    significado: "Cuidar los compromisos y promesas realizados, mostrando integridad y fidelidad.",
    comoTrabajarla:
      "Cumplir con los compromisos y promesas, mantener la palabra dada y actuar con fidelidad hacia los acuerdos.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y relaciones donde se hayan realizado compromisos y promesas.",
    desequilibrio:
      "Falta de cumplimiento de los compromisos, falta de integridad en los acuerdos y falta de fidelidad hacia los demás.",
  },
  {
    id: 81,
    nombre: "Kavod (Honor)",
    significado: "Mostrar respeto y honor hacia los demás, reconociendo su valor y dignidad.",
    comoTrabajarla:
      "Tratar a los demás con respeto, escuchar activamente y valorar las opiniones y contribuciones de los demás.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, buscando oportunidades para mostrar honor y respeto hacia los demás.",
    desequilibrio:
      "Falta de respeto y honor hacia los demás, falta de reconocimiento de su valor y dignidad.",
  },
  {
    id: 82,
    nombre: "Hishtavut (Equidad)",
    significado:
      "Promover la equidad y la justicia en todas las áreas de la vida, tratando a todos por igual.",
    comoTrabajarla:
      "Evitar la discriminación y la parcialidad, tratar a todos con justicia y brindar igualdad de oportunidades.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, buscando promover la equidad y la igualdad.",
    desequilibrio: "Falta de equidad y justicia, discriminación y trato desigual hacia los demás.",
  },
  {
    id: 83,
    nombre: "Bitajon BeShem (Confianza en lo divino)",
    significado:
      "Tener confianza en lo divino y en su plan, dejando preocupaciones y ansiedades en sus manos.",
    comoTrabajarla:
      "Practicar la confianza en lo divino, soltar preocupaciones y entregarlas en manos de lo divino.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, confiando en lo divino y dejando preocupaciones en sus manos.",
    desequilibrio:
      "Falta de confianza en lo divino, preocupación excesiva y falta de entrega de preocupaciones.",
  },
  {
    id: 84,
    nombre: "Menajem Aveilim (Consolar a los afligidos)",
    significado:
      "Brindar consuelo y apoyo a aquellos que están pasando por momentos de dolor y tristeza.",
    comoTrabajarla:
      "Mostrar empatía y compasión hacia los afligidos, brindar apoyo emocional y ofrecer ayuda práctica.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones donde alguien esté pasando por dolor y tristeza, buscando brindar consuelo.",
    desequilibrio:
      "Falta de empatía y compasión hacia los afligidos, falta de apoyo emocional y falta de ayuda práctica.",
  },
  {
    id: 85,
    nombre: "Netilat Yadayim (Lavado de manos)",
    significado:
      "Practicar el lavado ritual de manos como una forma de purificación y preparación espiritual.",
    comoTrabajarla:
      "Realizar el lavado ritual de manos de acuerdo a las enseñanzas y prácticas tradicionales.",
    cuandoTrabajarla:
      "Siempre, antes de realizar actividades sagradas y como parte de las prácticas de purificación espiritual.",
    desequilibrio:
      "Falta de práctica del lavado ritual de manos, falta de atención a las prácticas de purificación espiritual.",
  },
  {
    id: 86,
    nombre: "Peruj Kedushá (Difusión de santidad)",
    significado: "Difundir la santidad en el mundo a través de nuestras acciones y palabras.",
    comoTrabajarla:
      "Ser consciente del impacto de nuestras acciones y palabras en el mundo, buscando difundir la santidad y el bien.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, buscando ser un canal de santidad y llevar luz al mundo.",
    desequilibrio:
      "Falta de conciencia del impacto de nuestras acciones y palabras, falta de intención de difundir la santidad en el mundo.",
  },
  {
    id: 87,
    nombre: "Hishtavut HaNefesh (Equilibrio del alma)",
    significado:
      "Buscar el equilibrio y la armonía en el estado interno del alma, cultivando la paz interior.",
    comoTrabajarla:
      "Buscar el equilibrio emocional y espiritual, practicar la autocompasión y el cuidado del bienestar emocional.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, priorizando el equilibrio y la paz interior.",
    desequilibrio:
      "Desequilibrio emocional y espiritual, falta de paz interior y dificultad para encontrar armonía en el estado del alma.",
  },
  {
    id: 88,
    nombre: "Aniut (Sencillez)",
    significado: "Cultivar la sencillez en la vida, evitando el exceso y la ostentación.",
    comoTrabajarla:
      "Simplificar la vida, buscar la alegría en las cosas simples y evitar la búsqueda excesiva de lujos y posesiones materiales.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, buscando la sencillez y la conexión con las cosas esenciales.",
    desequilibrio:
      "Exceso y ostentación, búsqueda desmedida de lujos y falta de aprecio por las cosas simples de la vida.",
  },
  {
    id: 89,
    nombre: "Shmirat HaNefesh (Cuidado del alma)",
    significado:
      "Cuidar y nutrir el bienestar espiritual del alma, practicando la conexión con lo divino y el crecimiento espiritual.",
    comoTrabajarla:
      "Dedicar tiempo a prácticas espirituales, cultivar la conexión con lo divino y nutrir el alma a través de la oración y la meditación.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, priorizando el cuidado y el crecimiento espiritual.",
    desequilibrio:
      "Descuido del alma, falta de prácticas espirituales y falta de conexión con lo divino.",
  },
  {
    id: 90,
    nombre: "Tzelem Elokim (Imagen divina)",
    significado:
      "Reconocer la presencia de la imagen divina en cada ser humano, mostrando respeto y trato digno hacia todos.",
    comoTrabajarla:
      "Tratar a todos los seres humanos con respeto y dignidad, reconociendo la chispa divina en cada uno.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, priorizando el respeto y el trato digno hacia todos.",
    desequilibrio:
      "Falta de respeto y trato digno hacia los demás, falta de reconocimiento de la imagen divina en cada ser humano.",
  },
  {
    id: 91,
    nombre: "Kehilá (Comunidad)",
    significado:
      "Valorar y apoyar la comunidad, promoviendo la colaboración y el bienestar colectivo.",
    comoTrabajarla:
      "Participar activamente en la comunidad, mostrar apoyo mutuo y trabajar por el bienestar colectivo.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, priorizando el bienestar y la colaboración comunitaria.",
    desequilibrio:
      "Falta de participación en la comunidad, falta de apoyo mutuo y falta de contribución al bienestar colectivo.",
  },
  {
    id: 92,
    nombre: "Kavod HaTorah (Respeto por la Torá)",
    significado:
      "Mostrar respeto y reverencia hacia la Torá, reconociendo su importancia y valor espiritual.",
    comoTrabajarla:
      "Estudiar la Torá con reverencia y respeto, aplicando sus enseñanzas en la vida diaria.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y pensamientos relacionados con la Torá y sus enseñanzas.",
    desequilibrio:
      "Falta de respeto y reverencia hacia la Torá, falta de estudio y aplicación de sus enseñanzas.",
  },
  {
    id: 93,
    nombre: "Yirat Shamayim (Temor a lo divino)",
    significado:
      "Cultivar un temor reverencial hacia lo divino, reconociendo su grandeza y trascendencia.",
    comoTrabajarla:
      "Desarrollar una actitud de reverencia y temor sagrado hacia lo divino, recordando la grandeza y la trascendencia divina.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, cultivando el temor reverencial hacia lo divino.",
    desequilibrio:
      "Falta de temor reverencial hacia lo divino, falta de reconocimiento de la grandeza divina.",
  },
  {
    id: 94,
    nombre: "Tikkun Middot (Corrección de las cualidades)",
    significado: "Trabajar en la mejora y corrección de las propias cualidades y comportamientos.",
    comoTrabajarla:
      "Identificar las cualidades que necesitan mejora y trabajar en su corrección, buscando un crecimiento personal constante.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, priorizando la mejora y el crecimiento personal.",
    desequilibrio:
      "Falta de atención a las propias cualidades y comportamientos, falta de disposición para el crecimiento personal.",
  },
  {
    id: 95,
    nombre: "Kedushat HaShabbat (Santidad del Shabbat)",
    significado:
      "Honrar y observar el Shabbat como un día sagrado, dedicado al descanso y la conexión espiritual.",
    comoTrabajarla:
      "Observar las prácticas y rituales del Shabbat, dedicando tiempo al descanso, la oración y la conexión espiritual.",
    cuandoTrabajarla: "Cada semana, durante el Shabbat, honrando y observando este día sagrado.",
    desequilibrio:
      "Falta de observancia del Shabbat, falta de dedicación al descanso y la conexión espiritual en este día.",
  },
  {
    id: 96,
    nombre: "Lev Tov (Corazón bondadoso)",
    significado:
      "Cultivar un corazón bondadoso y compasivo hacia los demás, mostrando amor y empatía.",
    comoTrabajarla:
      "Practicar la compasión y el amor hacia los demás, mostrando actos de bondad y buscando ayudar a quienes lo necesiten.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, priorizando la compasión y el amor hacia los demás.",
    desequilibrio:
      "Falta de compasión y empatía hacia los demás, falta de actos de bondad y dificultad para mostrar amor.",
  },
  {
    id: 97,
    nombre: "Shmirat HaBeriyot (Cuidado de la creación)",
    significado:
      "Cuidar y preservar la creación divina, mostrando respeto hacia el medio ambiente y todas las formas de vida.",
    comoTrabajarla:
      "Adoptar prácticas sostenibles, mostrar respeto hacia el medio ambiente y ser consciente del impacto de nuestras acciones en la creación.",
    cuandoTrabajarla:
      "Siempre, en todas las decisiones y acciones, priorizando el cuidado y la preservación de la creación.",
    desequilibrio:
      "Falta de cuidado y respeto hacia el medio ambiente, falta de conciencia del impacto de nuestras acciones en la creación.",
  },
  {
    id: 98,
    nombre: "Kabbalat Ol (Aceptación del yugo divino)",
    significado:
      "Aceptar y abrazar la voluntad divina en nuestras vidas, confiando en que todo sucede según un plan divino.",
    comoTrabajarla:
      "Cultivar la aceptación de las circunstancias y la confianza en lo divino, entregando nuestras preocupaciones y deseos en manos de lo divino.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, practicando la aceptación y confianza en lo divino.",
    desequilibrio:
      "Resistencia a aceptar las circunstancias, falta de confianza en lo divino y dificultad para soltar preocupaciones y deseos.",
  },
  {
    id: 99,
    nombre: "Or LaGoyim (Luz para las naciones)",
    significado:
      "Ser una luz y un ejemplo positivo para el mundo, compartiendo los valores espirituales y éticos del judaísmo.",
    comoTrabajarla:
      "Vivir de acuerdo a los valores del judaísmo, promoviendo la ética y el bienestar en la sociedad.",
    cuandoTrabajarla:
      "Siempre, en todas las interacciones y relaciones, buscando ser un ejemplo positivo y difundir la luz en el mundo.",
    desequilibrio:
      "Falta de compromiso con los valores éticos y espirituales del judaísmo, falta de contribución positiva a la sociedad.",
  },
  {
    id: 100,
    nombre: "Ahavat HaShem (Amor a lo divino)",
    significado:
      "Cultivar un amor profundo y devoto hacia lo divino, reconociendo su presencia en todo.",
    comoTrabajarla:
      "Cultivar la conexión y el amor hacia lo divino a través de la oración, la meditación y la contemplación de lo sagrado.",
    cuandoTrabajarla:
      "Siempre, en todas las situaciones y decisiones, buscando cultivar el amor y la conexión con lo divino.",
    desequilibrio:
      "Falta de conexión y amor hacia lo divino, falta de reconocimiento de su presencia en todo.",
  },
];

export default tablaMidot;
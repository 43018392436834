import * as React from 'react';
 
import Box from '@mui/material/Box';
 
 
 

const Jeshbon = () => {
  return (
    <section nosotros className="mb-5">
      <div className="bg-dark text-bg-dark pb-2 mb-5 ps-5  mb-1 text-center">
        <h3> El Jeshbón HaNefesh </h3>
      </div>
      <Box sx={{ flexGrow: 1, }}>
 <p>El Jeshbón HaNefesh, también conocido como "examen de conciencia" o "evaluación del alma", es un concepto espiritual y ético en el judaísmo que se refiere a la introspección y reflexión personal sobre nuestras acciones, pensamientos y emociones. Es un proceso de evaluación interna que tiene como objetivo identificar y corregir cualquier comportamiento negativo o desviado, y cultivar cualidades positivas y virtuosas.</p>
 <p>El Jeshbón HaNefesh se basa en la idea de que todos somos responsables de nuestras acciones y debemos esforzarnos constantemente por mejorar y crecer en nuestro desarrollo moral y espiritual. Implica analizar nuestros actos, examinar nuestras motivaciones y deseos, y evaluar si nuestras acciones están en línea con los valores y enseñanzas del judaísmo.</p>
 <p>Durante el Jeshbón HaNefesh, nos preguntamos a nosotros mismos si hemos sido honestos, justos, compasivos y respetuosos en nuestras interacciones con los demás. Reflexionamos sobre nuestras fortalezas y debilidades, y buscamos formas de mejorar y crecer en nuestras relaciones, nuestra conexión con Dios y nuestro servicio a la comunidad.</p>
 <p>Este proceso de autoevaluación se realiza de manera regular, como parte de las festividades judías y especialmente en el período previo a Rosh Hashaná y Yom Kipur, cuando se enfatiza el arrepentimiento y la búsqueda de la reconciliación con Dios y con los demás. El Jeshbón HaNefesh nos desafía a reconocer nuestros errores, pedir perdón a quienes hemos lastimado y comprometernos a hacer cambios positivos en nuestras vidas.</p>
 <p>En resumen, el Jeshbón HaNefesh es un proceso de autoevaluación y reflexión espiritual que nos permite examinar nuestro comportamiento, corregir nuestros errores y cultivar cualidades virtuosas. Es un componente fundamental del crecimiento y desarrollo personal en el judaísmo, y nos anima a ser conscientes de nuestras acciones y a buscar constantemente la mejora y el alineamiento con los valores y enseñanzas judías.</p>

      </Box>
    </section>
  );
}
export default Jeshbon
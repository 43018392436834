import React from "react";
import { List } from "bootstrap-4-react";
import Container from "react-bootstrap/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import tablaMeses from "../Tablas/Tablameses";

function Mes({ mes }) {
  return (
    <section Mes>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h1> {mes} </h1>
      </div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          {tablaMeses
            .filter((c) => c.nombre === mes.toString())
            .map((p, idx) => (
              <Grid container spacing={2}>
                <Grid item xs={4} >
                  <h1>{p.nombre} {p.hebreo}</h1>
                  <img
                    className="d-block w-100 image-responsive justify-content-center ps- mb-5 mt-3"
                    alt={p.descripcion}
                    src={p.url}
                  />
                
                </Grid>
                <Grid item xs={8} className="mt-5">
                  <p>{p.parrafo1}</p>
                  <p>{p.parrafo2}</p>
                  <p>{p.parrafo3}</p>
                  <p>{p.parrafo4}</p>
                  <Box
                    component="form"
                    sx={{ "& > :not(style)": { ml: 10, width: "36ch", mt: 1 } }}
                  >
                    <h6>Midot para trabajar</h6>
                    <List>
                      {p.midot.map((q, idx1) => (
                        <List.Item> {q}</List.Item>
                      ))}
                    </List>
                    <h6>Festividades del mes</h6>
                    <List>
                      {p.festividades.map((q, idx1) => (
                        <List.Item> {q}</List.Item>
                      ))}
                    </List>
                  </Box>

                  <Button
                    variant="text"
                    size="small"
                    className="mt-3"
                    color="success"
                    href="/Meses"
                  >
                    Volver
                  </Button>
                </Grid>
              </Grid>
            ))}
        </Box>
      </Container>
    </section>
  );
}

export default Mes;

import { Container } from "react-bootstrap";
import React from "react";
import Omernro from '../../pages/Omer/Omernro';
const Omer = ({ page, setPage }) => {
  return (
    <section Omer>
      <div className="bg-dark text-bg-dark pb-2 ps-5  mb-1 text-center">
        <h3> Cuenta del Omer</h3>
      </div>
      <Container>
        <p>La cuenta del Omer es una práctica que se lleva a cabo durante el período de tiempo conocido como "Omer". El Omer es un conteo de siete semanas que comienza en el segundo día de la festividad de Pésaj (Pascua judía) y concluye en el día antes de la festividad de Shavuot.</p>
        <p>El mandamiento de contar el Omer proviene de la Torá, específicamente del libro de Levítico 23:15-16, donde se ordena contar 49 días desde el día siguiente a la ofrenda de la gavilla de la siega de la cebada (Omer) durante Pésaj hasta la ofrenda del trigo nuevo en Shavuot.   </p>
        <p>Durante este período de 49 días, los judíos observantes llevan a cabo el recuento diario del Omer. La cuenta se realiza al anochecer, después de que cae la oscuridad, y se pronuncia una bendición especial seguida de la recitación del número del día correspondiente. Por ejemplo, en la primera noche se dirá: "Hoy es el primer día del Omer" y así sucesivamente hasta completar los 49 días.</p>
        <p>La práctica de contar el Omer tiene varios significados simbólicos. Históricamente, el Omer se asociaba con la ofrenda de los primeros frutos de la cosecha en el Templo de Jerusalén. Además, el período del Omer es considerado un tiempo de duelo en la tradición judía debido a una plaga que ocurrió en el pasado que causó la muerte de miles de estudiantes de Torá. Por lo tanto, se evitan celebraciones festivas y se observan ciertas restricciones durante este tiempo, como la prohibición de realizar bodas, cortarse el cabello o escuchar música alegre.</p>
        <p>La cuenta del Omer también se considera un tiempo de preparación espiritual para la festividad de Shavuot, que conmemora la entrega de la Torá en el monte Sinaí. Cada día del Omer se asocia con un atributo o sefirá específico en la tradición kabbalística judía, lo que brinda la oportunidad de reflexionar sobre diferentes aspectos de la vida y trabajar en el crecimiento personal y espiritual.</p>
        <p>En resumen, la cuenta del Omer es una práctica en la que se realiza un recuento diario durante siete semanas desde Pésaj hasta Shavuot. Tiene un significado simbólico relacionado con la agricultura, el duelo y la preparación espiritual para la festividad de Shavuot.</p>
      </Container>
      <Omernro page={1} setPage={setPage} />
    </section>
  );
};

export default Omer;